import React, {useEffect, useState} from 'react';
import style from './header.module.css'
import {Link, useLocation, useNavigate} from "react-router-dom";
import {
    ABOUT,
    BUSINESS_ACCOUNT,
    CONTACT_US,
    FAQ,
    PERSONAL_ACCOUNT,
    AGENT_PROGRAM
} from "../../Constants/RoutesConstants";
import TransparentButton from "../ui/TransparentButton/TransparentButton";
import MyBtn from "../ui/MyBtn/MyBtn";
import { useAppSelector } from '../../hooks/redux';

const Header = () => {

    const location = useLocation()
    const [isHeaderShow, setIsHeaderShow] = useState(true)
    const token = useAppSelector(state => state.userReducer.userToken)
    const kycVerificationStatus = useAppSelector(state => state.userReducer.user.flags.kycVerificationStatus)
    const clientId: string | null = useAppSelector(state => state.userReducer.user.userData.clientId)
    const firstName: string | null = useAppSelector(state => state.userReducer.user.userData.firstName)
    const lastName: string | null = useAppSelector(state => state.userReducer.user.userData.lastName)
    const companyName: string | null = useAppSelector(state => state.userReducer.user.userData.companyName)    
    const navigate = useNavigate();
    
    const backToPersonalAccount = () => {
        if (kycVerificationStatus !== "Approved") {
            navigate("/personal_account/personal/settings");
        } else {
            navigate('/personal_account/accounts')
        }
    }

    useEffect(() => {
        if (
            location.pathname.includes("personal_account") ||
            location.pathname.includes("login") ||
            location.pathname.includes("sign_up") ||
            location.pathname.includes("kyc_success") ||
            location.pathname.includes("kyc_declined") ||
            location.pathname.includes("kyc_failure")
        ) {
            setIsHeaderShow(false)
        } else {
            setIsHeaderShow(true)
        }
    }, [location.pathname])

    const setActiveTab = (pathname: string) => {
        return location.pathname.includes(pathname)
    }

    return (
        <>
            {
                isHeaderShow &&
                <header className={style.container}>
                    <div className={style.block}>

                        <div className={style.logo_block}>
                            <Link className={style.header_link} to="/">
                                <div className={style.logo}></div>
                            </Link>
                        </div>

                        <div className={style.links_block}>
                            <Link className={`${style.header_link} ${setActiveTab(PERSONAL_ACCOUNT) ? style.header_link_active : ''}`} 
                                  to={PERSONAL_ACCOUNT}
                            >
                                Personal
                            </Link>
                            <Link className={`${style.header_link} ${setActiveTab(BUSINESS_ACCOUNT) ? style.header_link_active : ''}`} 
                                  to={BUSINESS_ACCOUNT}
                            >
                                Business
                            </Link>
                            <Link className={`${style.header_link} ${setActiveTab(ABOUT) ? style.header_link_active : ''}`} 
                                  to={ABOUT}
                            >
                                About
                            </Link>
                            <Link className={`${style.header_link} ${setActiveTab(FAQ) ? style.header_link_active : ''}`} 
                                  to={FAQ}
                            >
                                FAQ
                            </Link>
                            <Link className={`${style.header_link} ${setActiveTab(AGENT_PROGRAM) ? style.header_link_active : ''}`} 
                                  to={AGENT_PROGRAM}
                            >
                                Agent Program
                            </Link>
                            <Link className={`${style.header_link} ${setActiveTab(CONTACT_US) ? style.header_link_active : ''}`} 
                                  to={CONTACT_US}
                            >
                                Contact Us
                            </Link>
                        </div>
                    </div>
                    {
                        token !== "" ?
                            <div onClick={() => backToPersonalAccount()} className={style.round}>
                                <div className={style.user_typography}>
                                    <div><b><p className={style.name}>{firstName} {lastName} {companyName}</p></b></div>
                                    <div><p className={style.id}>ID: {clientId}</p></div>
                                </div>
                                <div className={style.user}></div>
                            </div>
                        : <div className={style.buttons_block}>
                            <div className={style.button_wrapper_short}>
                                <Link to={"/login"}>
                                    <TransparentButton title={"Sign In"}/>
                                </Link>
                            </div>
                            <div className={style.button_wrapper}>
                                <Link to={"/sign_up"}>
                                    <MyBtn title={"Open account"}/>
                                </Link>
                            </div>
                        </div>
                    }
                </header>
            }
        </>
    );
};

export default Header;
