import React, {useEffect, useState} from 'react';
import styles from './CookiePopup.module.css';
import close from '../../assets/img/close_popup.svg'
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';

const CookiePopup = () => {
    const location = useLocation()

    const [isPopUpShow, setIsPopUpShow] = useState(true)
    const [isPopUpClosed, setIsPopUpClosed] = useState(false)

    useEffect(() => {
        if (
            location.pathname.includes("personal_account") ||
            location.pathname.includes("login") ||
            location.pathname.includes("sign_up") ||
            location.pathname.includes("kyc_success") ||
            location.pathname.includes("kyc_declined") ||
            location.pathname.includes("kyc_failure")
        ) {
            setIsPopUpShow(false)
        } else {
            setIsPopUpShow(true)
        }
    }, [location.pathname])

    useEffect(()=> {
        if (localStorage.getItem('cookieConsent') === 'true') {
            setIsPopUpClosed(true);
        }
    }, [])

    const closePopUp = () => {
        setIsPopUpClosed(true)

        localStorage.setItem('cookieConsent', 'true');
      
        setTimeout(function() {
          localStorage.removeItem('cookieConsent');
        }, 7 * 24 * 60 * 60 * 1000); 
    }

    return (
        <>
            {
                isPopUpShow && !isPopUpClosed &&
                <div className={styles.cookie_popup}>
                    <div className={styles.cookie_body}>
                        <div className={styles.cookie_content}>
                            We use cookies to offer you a better experience, analyze site traffic, in order to make the 
                            navigation of our website easy, safe for our users and to provide a browsing experience 
                            that is unique to you.
                            <br/>        
                            <br/>  
                            Before continuing to use our website, you agree and accept our&nbsp; 
                            <Link to={'/cookie_policy'}>Cookie Policy</Link>.
                        </div>
                        <img src={close} className={styles.close_cookie} onClick={closePopUp}/>
                    </div>
                </div>
            }
        </>
    );
};

export default CookiePopup;
