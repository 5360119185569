import {userLimitsType} from "../store/reducers/UserSlice";
import {Dispatch, SetStateAction} from "react";

export const areLimitsExceeded = (
    limitsInfo: userLimitsType,
    setLimitType: Dispatch<SetStateAction<string | null>>,
    setIsLimitPopupOpen: Dispatch<SetStateAction<boolean | null>>
) => {
    if ((limitsInfo?.currentDailyAmount ?? 0) >= (limitsInfo?.dailyLimit ?? 0))
    {
        setLimitType('daily')
        setIsLimitPopupOpen(true)
    }
    else if ((limitsInfo.currentMonthlyAmount ?? 0) >= (limitsInfo.monthlyLimit ?? 0)) {
        setLimitType('monthly')
        setIsLimitPopupOpen(true)
    }
}
