import React, {ReactNode} from 'react';
import s from './TransparentButton.module.css'
import {Button, ConfigProvider} from 'antd';
import classnames from "classnames";

type PropsType = {
    title: string,
    onClick?: (e?: any) => void;
    type?: "button" | "submit" | "reset" | undefined;
    style?: React.CSSProperties;
    icon?: ReactNode;
    large?: boolean;
    medium?: boolean;
    small?: boolean;
    isPersonalAccountBtn?: boolean;
    buttonType?: 'confirm' | 'error' | 'info';
    disabled?: boolean | undefined;
}

const TransparentButton: React.FC<PropsType> = ({isPersonalAccountBtn, type, title, icon, large, medium, small, buttonType, ...props}) => {
    return (
        <ConfigProvider theme={{
            token: {
                colorBgContainer: 'transparent',
                colorBorder: '#2046A1',
                colorPrimaryHover: '#89C0E9',
                motionEaseInOut: '0.3s'
            }
        }}>
            <Button
                htmlType={type}
                icon={icon}
                className={classnames(isPersonalAccountBtn ? s.personal_button : s.button, {
                    [s.large]: large,
                    [s.medium]: medium,
                    [s.small]: small,
                    [s.confirm]: buttonType === 'confirm',
                    [s.error]: buttonType === 'error',
                    [s.info]: buttonType === 'info',
                }, 
                props.disabled ? s.disabled : ''
                )}
                {...props}
            >
                {title}
            </Button>
        </ConfigProvider>
    );
};

export default TransparentButton;
