import axios from "axios";
import {
    LoginDataType,
    RegistrationDataType,
} from "../store/reducers/ActionCreators";
import {
    EmailVereficationDataType
} from "../components/Autentications/EmailAutentication/EmailAutenticationLogin/EmailAutenticationLogin";
import {IAccounts, IContactData, ITransacton} from "../types/interfaces";
import {IExchangeBody} from "../pages/PersonalAccount/Currency/CurrencyExchange/ExchangeStepTwo/ExchangeStepTwo";

let baseSettings = {
    headers: {
        'Content-Type': 'application/json',
    },
}

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    ...baseSettings
}) as any

export const Api = {
    login(data: LoginDataType) {
        return instance.post('api/login/additionals/list', data)
    },
    finalLogin(data: LoginDataType) {
        return instance.post('api/login', data)
    },
    logout(sessionId: number | null, sessionEnd: string, token: string) {
        return instance.post('api/user/logout', {sessionId, sessionEnd}, {headers: {Authorization: `Bearer ${token}`}})
    },
    getUserAccountList(token: string) {
        return instance.get('api/user-account/list', {headers: {Authorization: `Bearer ${token}`}})
    },
    registration(data: RegistrationDataType) {
        return instance.post('api/registrations', data)
    },
    sendPersonalKyc(token: string, data: any) {
        return instance.post('api/kyc/create', data, {headers: {Authorization: `Bearer ${token}`}})
    },
    getKycList(token: string) {
        return instance.get('api/kyc/list', {headers: {Authorization: `Bearer ${token}`}})
    },
    deleteKyc(token: string, id: number) {
        return instance.post('api/kyc/delete', {kycId: id}, {headers: {Authorization: `Bearer ${token}`}})
    },
    updateKyc(token: string, data: any) {
        return instance.post('api/kyc/update', data, {headers: {Authorization: `Bearer ${token}`}})
    },
    getTransactionsList(token: string, id: number | null) {
        return instance.get(`api/transaction/list/user-account/${id}`, {headers: {Authorization: `Bearer ${token}`}})
    },
    emailVerefication(data: EmailVereficationDataType) {
        return instance.post('api/verifies/emails ', data)
    },
    createAccounts(token: string, accounts: IAccounts, accountId: number | null) {
        return instance.post('/api/account/create', {accountId: accountId, ...accounts}, {headers: {Authorization: `Bearer ${token}`}})
    },
    getAccountsList(token: string, id: number | null, currency: string) {
        return instance.get(`api/account/list/user-account/${id}?totalCurrency=${currency}`, {headers: {Authorization: `Bearer ${token}`}})
    },
    get2Fa(token: string) {
        return instance.get('api/2fa/create', {headers: {Authorization: `Bearer ${token}`}})
    },
    send2FaCode(token: string, data: any) {
        return instance.post('api/2fa/verify', data, {headers: {Authorization: `Bearer ${token}`}})
    },
    sendTransfer(token: string, data: any) {
        return instance.post('/api/transaction/create', data, {headers: {Authorization: `Bearer ${token}`}})
    },
    createTemplate(token: string, data: ITransacton) {
        return instance.post('/api/payment-template/create', data, {headers: {Authorization: `Bearer ${token}`}})
    },
    getTemplateList(token: string, accountId: number | null) {
        return instance.post('/api/payment-template/list',{accountId}, {headers: {Authorization: `Bearer ${token}`}})
    },
    getTemplateById(token: string, id: string | number | undefined, accountId: number | null) {
        return instance.post(`/api/payment-template/item/${id}`, {accountId}, {headers: {Authorization: `Bearer ${token}`}})
    },
    deleteTemplate(token: string, id: number, accountId: number | null) {

        const config = {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
            params: {
                accountId,
            },
        };

        return instance.delete(`/api/payment-template/delete/${id}`, config);
    },
    sendExchange(body: IExchangeBody) {
        return instance.post(`/api/converter/convert`, body)
    },
    sendExchangeRevert(body: IExchangeBody) {
        return instance.post(`/api/converter/reverse-convert`, body)
    },
    sendCardRequest(token: string, data: any) {
        return instance.post('/api/card/create', data, {headers: {Authorization: `Bearer ${token}`}})
    },
    sendPhysicalCardRequest(token: string, data: any) {
        return instance.post('/api/card/request-physical', data, {headers: {Authorization: `Bearer ${token}`}})
    },
    getCardInfo(token: string) {
        return instance.get('/api/card/getDetails', {headers: {Authorization: `Bearer ${token}`}})
    },
    sendCardTopUp(token: string, data: any) {
        return instance.post('/api/card/top-up', data, {headers: {Authorization: `Bearer ${token}`}})
    },
    getCardTransactionList(token: string, san: string, year: string, month: string) {
        return instance.get('/api/card/getTransactions', {
            params: {
                year: year,
                month: month,
                san: san,
            }, headers: {Authorization: `Bearer ${token}`}
        })
    },
    activatePhysicalCard(token: string, data: any) {
        return instance.post('/api/card/activate-physical', data, {headers: {Authorization: `Bearer ${token}`}})
    },
    updateCard(token: string, data: any) {
        return instance.post('/api/card/update-card-holder', data, {headers: {Authorization: `Bearer ${token}`}})
    },
    suspendCard(token: string, data: any) {
        return instance.post('/api/card/suspend', data, {headers: {Authorization: `Bearer ${token}`}})
    },
    resetPassword(data: any) {
        return instance.post(`/api/user/password/refresh`, data)
    },
    createNewPassword(token: string, data: any) {
        return instance.post(`api/user/password/change`, data, {headers: {Authorization: `Bearer ${token}`}})
    },
    getTopUpInfo(token: string, data: any) {
        return instance.post(`api/top-up`, data, {headers: {Authorization: `Bearer ${token}`}})
    },
    addNewPhone(token: string, data: string) {
        return instance.post(`api/phone/create`, data, {headers: {Authorization: `Bearer ${token}`}})
    },
    sendDataForNewPassword(emailAdress: string) {
        return instance.post(`api/reset-password`, {email: emailAdress})
    },
    setNewPassword(link: string, password: string, passwordConfirmation: string) {
        return instance.post(`api/reset-password/${link}`, {password: password, password_confirmation: passwordConfirmation})
    },
    getCodeForVerifyPhone(token: string, data: string) {
        return instance.post(`api/phone/send/verify/code`, {phoneId: data}, {headers: {Authorization: `Bearer ${token}`}})
    },
    sendVerifyCode(token: string, phoneId: string, code: string) {
        return instance.post(`api/phone/verify`, {
            phoneId: phoneId,
            code: code
        }, {headers: {Authorization: `Bearer ${token}`}})
    },
    setSmsCode(token: string) {
        return instance.get(`api/user/phone-verify/active`, {headers: {Authorization: `Bearer ${token}`}})
    },
    disableSmsCode(token: string) {
        return instance.get(`api/user/phone-verify/disable`, {headers: {Authorization: `Bearer ${token}`}})
    },
    disable2faCode(token: string) {
        return instance.get(`api/2fa/disable`, {headers: {Authorization: `Bearer ${token}`}})
    },
    getStatement(token: string, accountInfo: any) {
        return instance.post(`api/file/statment/pdf`, accountInfo, {headers: {Authorization: `Bearer ${token}`}})
    },
    sendContactForm(data: IContactData) {
        return instance.post(`api/mailer/sendContactFormData`, data)
    },
    sendCodeToNewEmail(token: string, email: string) {
        return instance.post(`/api/user/email/sendCodeToNewEmail`, {newEmail: email}, {headers: {Authorization: `Bearer ${token}`}})
    },
    sendConfirmationCodeFromOldEmail(codeFromCurrentEmail: string, token: string) {
        return instance.post(`/api/user/email/verifyCurrentEmail`, {codeFromCurrentEmail}, {headers: {Authorization: `Bearer ${token}`}})
    },
    sendConfirmationCodeFromNewEmail(newEmail: string, codeFromNewEmail: string, codeFromCurrentEmail: string, token: string) {
        return instance.post(`api/user/email/verifyAndChange`, {newEmail, codeFromNewEmail, codeFromCurrentEmail}, {headers: {Authorization: `Bearer ${token}`}})
    },
    getNewEmail(token: string) {
        return instance.get(`api/user/email`, {headers: {Authorization: `Bearer ${token}`}})
    },
    getAccoutDetailsByAccountType(token: string, id: unknown) {
        return instance.get(`api/user-account/${id}`, {headers: {Authorization: `Bearer ${token}`}})
    },
    getAvaliableAccountsForSending(token: string, selectionType: unknown, data: any) {
        return instance.post(`api/account/transfer-type/${selectionType}/list`, data, {headers: {Authorization: `Bearer ${token}`}})
    },
    createNewTypeOfAccountThunk(token: string, data: any) {
        return instance.post('api/user-account/create', data, {headers: {Authorization: `Bearer ${token}`}})
    },
    sendDataToStartVerification(token: string, accountId: number | null) {
        return instance.post('api/ondato/identity-verifications', {accountId}, {headers: {Authorization: `Bearer ${token}`}})
    },
    setAutoLogoutSetting(token: string, payload: boolean) {
        return instance.post('api/user/setAutoLogoutSetting', {autoLogout: payload}, {headers: {Authorization: `Bearer ${token}`}})
    },
    setLogoutIfIPChanged(token: string, payload: boolean) {
        return instance.post('api/user/setlogoutIfIpChanges', {logoutIfIpChanges: payload}, {headers: {Authorization: `Bearer ${token}`}})
    },
    sendEmailOnLogin(token: string, payload: boolean) {
        return instance.post('api/user/setSendEmailOnLogin', {sendEmailOnLogin: payload}, {headers: {Authorization: `Bearer ${token}`}})
    },
    setSaveLoginHistory(token: string, payload: boolean) {
        return instance.post('api/user/setSaveLoginHistory', {saveLoginHistory: payload}, {headers: {Authorization: `Bearer ${token}`}})
    },
    getLoginHistory(token: string, payload: string) {
        return instance.post('api/user/getLoginHistory', {currentDatetime: payload}, {headers: {Authorization: `Bearer ${token}`}})
    },
    setNewWithdrawalSettings(token: string, settings: {blockWithdrawal: boolean, verifyWithdrawal: boolean, withdrawalCode: string}) {
        return instance.post('api/user/setWithdrawalSettings', settings, {headers: {Authorization: `Bearer ${token}`}})
    },
    sendWithdrawalCode(token: string, code: string) {
        return instance.post('api/user/verifyWithdrawalCode', {withdrawalCode: code}, {headers: {Authorization: `Bearer ${token}`}})
    },
    sendVerifyCodeToEmail(token: string) {
        return instance.post('api/user/sendWithdrawalCode', {}, {headers: {Authorization: `Bearer ${token}`}})
    },
    checkWithdrawalStatus(token: string) {
        return instance.post('api/user/checkWithdrawalStatus', {}, {headers: {Authorization: `Bearer ${token}`}})
    },
    sendVerifyCodeToEmailForChangeSettings(token: string) {
        return instance.post('api/user/sendWithdrawalSettingsCode', {}, {headers: {Authorization: `Bearer ${token}`}})
    },
    verifyOldEmail(token: string) {
        return instance.post(`/api/user/email/sendCodeToCurrentEmail`, {}, {headers: {Authorization: `Bearer ${token}`}})
    },
    checkAvailableEmail(email: string) {
        return instance.post(`/api/user/email/validate`, {email: email})
    },
    getLimitsData(token: string, userNumber: string | null) {
        return instance.post(`api/limits`, {userNumber}, {headers: {Authorization: `Bearer ${token}`}})
    },
    setNewLimits(token: string, data: any) {
        return instance.post(`/api/creates/limits/requests`, data, {headers: {Authorization: `Bearer ${token}`}})
    },
    getAccountConfirmationLetter(token: string, accountId: number | null, accountNumber: string) {
        return instance.get(`/api/user-account/${accountId}/confirmation-letter/${accountNumber}`, {
            responseType: 'blob',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/pdf',
            }
        })
    },
    getRequestedLimitsData(token: string, userId: number | null) {
        return instance.get(`/api/limit/checkUserRequestedLimits?accountId=${userId}`, {headers: {Authorization: `Bearer ${token}`}})
    },
}

const optionsBicChecker = {
    headers: {
        'Accept': 'application/json',
        'X-Api-Key': 'sk_0e4e09b43bf90ec66c7df07e93137d53f9c056555893f3c07467cfabd9d8ebd0'
    }
};

const instanceBicChecker = axios.create({
    baseURL: 'https://swiftcodesapi.com/',
    ...optionsBicChecker
}) as any

export const ApiBicChecker = {
    getBicCode(iban: string) {
        return instanceBicChecker.get(`v1/ibans/${iban}`)
    },
}
