import {useEffect} from "react";
import {useAppDispatch} from "../../hooks/redux";
import useDebounce from "../../hooks/useDebounce";
import {useField, useFormikContext} from "formik";
import {
    checkAvaliableAccountsForSendingThunk,
} from "../../store/reducers/ActionCreators";

export const CurrencyHelper = (props: any) => {

    const [, , helpers] = useField<string>(props.name);
    const {setValue} = helpers;

    useEffect(() => {
        props.accountsList.map((account: any) => {
            if (account.accountMainIban === props.accNumberFrom) {
                setValue(account.currency)
            }
        })
    }, [props.accountsList, props.accNumberFrom])

    return null
}

export const CheckAvaliableAccountsByPhone = (props: any) => {

    const dispatch = useAppDispatch()
    const debouncedValue = useDebounce(props.phone, 1500);

    const [, , helpers] = useField<string>(props.name);
    const {setValue} = helpers;

    const [, , helpersAccNumberTo] = useField<string>(props.accNumberTo);
    const setAccNumberToHelper = helpersAccNumberTo.setValue;

    useEffect(() => {
        setAccNumberToHelper(props.phone)
        if (props.phone !== "" && props.phone !== undefined && props.phone !== null) {
            dispatch(checkAvaliableAccountsForSendingThunk(3, props.token, {
                phone: props.phone,
                currency: props.currency,
                transferType: 'phone'
            })).then((res) => {

                if (res.response && res.response.data.includes("User does not exist")) {
                    props.setIsAvailableAccountExists(false)
                }

                if (res.data.length === 1) {
                    setValue(res.data[0].number)
                } else if (res.data.length === 0) {
                    props.setIsAvailableAccountExists(false)
                } else if (res.data.length === 2) {
                    props.setIsListOfAccounts(res.data)
                }
            })
        }

    }, [debouncedValue])

    return null
}

export const CheckAvailableAccountsByEmail = (props: any) => {

    const dispatch = useAppDispatch()

    const debouncedValue = useDebounce(props.email, 1500);
    const [, , helpersHiddenAccountNumber] = useField<string>(props.name);
    const {setValue} = helpersHiddenAccountNumber;

    const [, , helpersAccNumberTo] = useField<string>(props.accNumberTo);
    const setAccNumberToHelper = helpersAccNumberTo.setValue;

    useEffect(() => {
        setAccNumberToHelper(props.email)
        if (props.email !== "" && props.email !== undefined && props.email !== null && props.currency !== '') {
            dispatch(checkAvaliableAccountsForSendingThunk(2, props.token, {
                email: props.email,
                currency: props.currency,
                transferType: 'email'
            })).then((res) => {

                console.log(res)

                if (res.response && res.response.data.includes("User does not exist")) {
                    props.setIsAvailableAccountExists(false)
                }

                if (res.data.length === 1) {
                    setValue(res.data[0].number)
                    props.setAvailableBeneficiaryAccounts(res.data)
                } else if (res.data.length === 0) {
                    props.setIsAvailableAccountExists(false)
                } else if (res.data.length === 2) {
                    props.setIsListOfAccounts(res.data)
                }
            })
        }
    }, [debouncedValue])

    return null
}

export const CheckAvaliableAccountByWalletId = (props: any) => {

    const dispatch = useAppDispatch()
    const [field, meta, helpers] = useField<string>(props.name);
    const {setValue} = helpers;

    const debouncedValue = useDebounce(props.account, 1500);

    useEffect(() => {
        if (props.account !== ""
            && props.account !== undefined
            && props.account !== null
            && props.currency !== ''
            && props.transferMethod === "Wallet Id") {
            dispatch(checkAvaliableAccountsForSendingThunk(1, props.token, {
                userNumber: props.account,
                currency: props.currency
            })).then((res) => {
                if (res.data) {
                    setValue(res.data[0].number)
                } else {
                    props.setIsAvailableAccountExists(false)
                }
            })
        }
    }, [debouncedValue, props.currency])

    return null
}

export const CheckAvaliableAccountByAccountNumber = (props: any) => {

    const dispatch = useAppDispatch()
    const debouncedValue = useDebounce(props.account, 1500);
    const [field, meta, helpersHiddenAccountNumber] = useField<string>(props.name);
    const {setValue} = helpersHiddenAccountNumber;

    useEffect(() => {
        if (props.account !== ""
            && props.account !== undefined
            && props.account !== null
            && props.currency !== '') {
            dispatch(checkAvaliableAccountsForSendingThunk(1, props.token, {
                userNumber: props.account,
                currency: props.currency
            })).then((res) => {
                if (res.data) {
                    setValue(res.data[0].number)
                } else {
                    props.setIsAvailableAccountExists(false)
                }
            })
        }
    }, [debouncedValue, props.currency])

    return null
}

export const ResetFieldByTransferMethod = (props: any) => {
    const {values} = useFormikContext();
    const [field, meta, helpers] = useField<string>(props.name);
    const {setValue} = helpers;

    useEffect(() => {
        if (props.isCreate) {
            props.handleReset()
            setValue("")
        }

        // @ts-ignore
    }, [values.transferMethod])

    return null
}
