import React from 'react';
import s from "./Faq.module.css";
import {motion} from 'framer-motion';
import {Collapse} from 'antd';
import minus from '../../../assets/img/minus.svg'
import plus from '../../../assets/img/plus.svg'
import screenshot1 from '../../../assets/img/faq/screenshot1.png'
import screenshot2 from '../../../assets/img/faq/screenshot2.png'
import screenshot3 from '../../../assets/img/faq/screenshot3.png'
import screenshot4 from '../../../assets/img/faq/screenshot4.png'
import screenshot5 from '../../../assets/img/faq/screenshot5.png'
import screenshot52 from '../../../assets/img/faq/screenshot5-2.png'
import screenshot6 from '../../../assets/img/faq/screenshot6.png'
import screenshot7 from '../../../assets/img/faq/screenshot7.png'
import screenshot71 from '../../../assets/img/faq/screenshot71.png'
import screenshot8 from '../../../assets/img/faq/screenshot8.png'
import screenshot81 from '../../../assets/img/faq/screenshot81.png'
import screenshot9 from '../../../assets/img/faq/screenshot9.png'
import screenshot91 from '../../../assets/img/faq/screenshot91.png'
import screenshot101 from '../../../assets/img/faq/screenshot101.png'
import screenshot11 from '../../../assets/img/faq/screenshot11.png'
import screenshot12 from '../../../assets/img/faq/screenshot12.png'
import screenshot13 from '../../../assets/img/faq/screenshot13.png'
import screenshot14 from '../../../assets/img/faq/screenshot14.png'
import screenshot15 from '../../../assets/img/faq/screenshot15.png'
import screenshot16 from '../../../assets/img/faq/screenshot16.png'

const {Panel} = Collapse;

const Faq = () => {

    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
            className={s.motion_div}
        >
            <div className={s.wrapper}>
                <div className={s.container}>
                    <div className={s.title}>
                        Frequently Asked Questions (FAQ)
                    </div>

                    <Collapse
                        className={s.my_collapse}
                        expandIconPosition={"end"}
                        bordered={false}
                        expandIcon={({isActive}) => isActive ?
                            <img className={s.icon_minus} src={minus} alt=""/> :
                            <img src={plus} className={s.icon_plus} alt=""/>}
                        style={{
                            background: "transparent",
                            alignItems: 'center',
                        }}
                    >
                        <Panel header={"How to open a bank account for private person?"} key={1}
                               className={s.panelStyle}>
                            <div className={s.collapse_text}>
                                <div className={s.flex}>
                                    <div className={s.text}>
                                        1. Visit <a href="https://www.luxpay.lt/"
                                                    className={s.link_small}>www.luxpay.lt</a> and click on “<b>Open
                                        Account</b>”
                                    </div>
                                    <div className={s.screenshot}>
                                        <img src={screenshot1} alt=""/>
                                    </div>
                                </div>

                                <div className={s.flex}>
                                    <div className={s.text}>
                                        2. Click on “<b>Create your private account</b>”. Do not choose “Submit business
                                        application”
                                        option. Even if you want to open a business account only, you, as CEO /
                                        signatory
                                        of the company, must complete KYC check first.
                                    </div>
                                    <div className={s.screenshot}>
                                        <img src={screenshot2} alt=""/>
                                    </div>
                                </div>

                                <div className={s.flex}>
                                    <div className={s.text}>
                                        3. Enter your personal information, email address, password which will be used
                                        for
                                        signing up and press “<b>Create Account</b>”. Accepting our Privacy policy,
                                        Terms of
                                        use and confirmation you are not a PEP is mandatory. If you are PEP, please
                                        contact us by sending email to compliance@luxpay.lt
                                    </div>
                                    <div className={s.screenshot}>
                                        <img src={screenshot3} alt=""/>
                                    </div>
                                </div>

                                <div className={s.flex}>
                                    <p className={s.text}>
                                        4. You will receive an email with verification link. There is a possibility to
                                        re-send
                                        verification link or use a different email if needed.
                                    </p>
                                    <div className={s.screenshot}>
                                        <img src={screenshot4} alt=""/>
                                    </div>
                                </div>

                                <div className={s.flex}>
                                    <div className={s.text}>
                                        5. After your email is verified, you will be requested to verify your phone
                                        number
                                        which will be used for signing up. SMS with 6-digit verification code will be
                                        sent to provided number.
                                    </div>
                                    <div className={s.screenshot}>
                                        <img src={screenshot5} alt=""/>
                                    </div>
                                </div>
                                <div className={s.flex}>
                                    <div className={s.text}>
                                    </div>
                                    <div className={s.screenshot}>
                                        <img src={screenshot52} alt=""/>
                                    </div>
                                </div>

                                <div className={s.flex}>
                                    <p className={s.text}>
                                        6. Next step is verification of ID document. You will be requested to choose a
                                        country where ID document has been issued and document type. If you are non-EU
                                        resident, please perform verification with your passport. Verification can be
                                        done using your computer and its webcam or continued on your phone. To complete
                                        this step, you will have to take live picture – your ID must be held against the
                                        camera.
                                    </p>
                                    <div className={s.screenshot}>
                                        <img src={screenshot6} alt=""/>
                                    </div>
                                </div>

                                <div className={s.flex}>
                                    <div className={s.text}>
                                        7. After a picture of ID document is taken, you will be asked to take a selfie.
                                        This
                                        can be done using your computer and its webcam or continued on your phone.
                                    </div>
                                    <div className={s.screenshot}>
                                        <img src={screenshot7} alt=""/>
                                    </div>
                                </div>

                                <div>
                                    <p>
                                        8. Once the selfie is taken, you will be requested to fill in LuxPay
                                        Questionnaire.
                                        Please note that questions marked with (*) are mandatory.
                                    </p>
                                </div>

                                <div className={s.flex}>
                                    <div className={s.text}>
                                        9. Once the Questionnaire is answered and submitted, you will see information
                                        that
                                        your application is being checked. The system might ask you to re-take picture
                                        of your ID due to unsatisfactory results, such as bad lighting.
                                    </div>
                                    <div className={s.screenshot}>
                                        <img src={screenshot8} alt=""/>
                                    </div>
                                </div>
                                <div className={s.flex}>
                                    <div className={s.text}>
                                    </div>
                                    <div className={s.screenshot}>
                                        <img src={screenshot9} alt=""/>
                                    </div>
                                </div>

                                <div>
                                    <p>
                                        10. Please note that each applicant is reviewed manually. The system might
                                        redirect
                                        to your online banking page. However, until your application is approved, you
                                        won’t be able to make or receive payments or top-up your account.
                                    </p>
                                </div>

                                <div>
                                    <p className={s.bold}>
                                        LuxPay Compliance department will contact you via email and request
                                        additional
                                        information and documents if need needed as well as provide you instructions
                                        how
                                        to make transactions if application is successful. Please contact us via
                                        email &nbsp;
                                        <a className={s.link_small}
                                           href="mailto:onboarding@luxpay.lt">onboarding@luxpay.lt</a> if you have
                                        any issues with onboarding process.
                                    </p>
                                </div>

                            </div>
                        </Panel>
                    </Collapse>

                    <Collapse
                        className={s.my_collapse}
                        expandIconPosition={"end"}
                        bordered={false}
                        expandIcon={({isActive}) => isActive ?
                            <img className={s.icon_minus} src={minus} alt=""/> :
                            <img src={plus} className={s.icon_plus} alt=""/>}
                        style={{
                            background: "transparent",
                            alignItems: 'center',
                        }}
                    >
                        <Panel header={"How to open a bank account for a company?"} key={2} className={s.panelStyle}>
                            <div className={s.collapse_text}>

                                <div className={s.flex}>
                                    <div className={s.text}>
                                        1. In order to apply for business account, CEO / signatory of the Company has to
                                        pass our KYC check and open personal account at first. If you do not have
                                        personal account, please contact <a className={s.link_small}
                                                                            href="mailto:onboarding@luxpay.lt."> onboarding@luxpay.lt</a>.
                                        If you do have
                                        personal
                                        account, go to your online banking, click on your name seen in upper right
                                        corner and choose “Add new company”.
                                    </div>
                                    <div className={s.screenshot}>
                                        <img className={s.fix_height} src={screenshot71} alt=""/>
                                    </div>
                                </div>

                                <div>
                                    <p>
                                        2. Fill in all fields about the Company (for Beneficial owner type, please
                                        choose
                                        “Ultimate beneficial owner”), agree with our Privacy Policy and Terms of Use and
                                        click on “<b>Create Company</b>”.
                                    </p>
                                </div>

                                <div className={s.flex}>
                                    <div className={s.text}>
                                        3. Under the “Upload company documents” click on “<b>Start</b>”.
                                    </div>
                                    <div className={s.screenshot}>
                                        <img src={screenshot81} alt=""/>
                                    </div>
                                </div>

                                <div>
                                    <p>
                                        4. Carefully read and agree with processing of Your personal data. Then press
                                        “<b>Next</b>”.
                                    </p>
                                </div>

                                <div className={s.flex}>
                                    <div className={s.text}>
                                        5. Fill in information about the Company and click “<b>Next</b>”. Please note
                                        some information
                                        will be already pre-filled from section 2.
                                    </div>
                                    <div className={s.screenshot}>
                                        <img src={screenshot91} alt=""/>
                                    </div>
                                </div>

                                <div className={s.flex}>
                                    <div className={s.text}>
                                        6. Provide information about the UBOs (persons owning at least 25% of the
                                        company)
                                        and Directors. Choose their positions within the company (one person can be both
                                        UBO and Director). You can add persons by clicking “<b>Add participant</b>” if
                                        the
                                        company has more than one Director and / or UBO. Delete persons by clicking
                                        “<b>Remove</b>”. ID documents of UBOs and Directors must be verified. You have
                                        two
                                        options: click “<b>Start verification now</b>” and the window with the check
                                        will open
                                        in a new tab. Alternatively, you may send the verification link via email or
                                        copy it. We strongly advise you to have prepared high-quality, unedited (saved
                                        directly from camera, not resized or saved as PDF) pictures of UBOs / Directors
                                        ID documents (both sides of ID card or passport) and perform this step yourself.
                                        Then click “<b>Next</b>”.
                                    </div>
                                    <div className={s.screenshot}>
                                        <img src={screenshot101} alt=""/>
                                    </div>
                                </div>

                                <div>
                                    <p>
                                        7. Fill in LuxPay Questionnaire and upload as many requested documents as you
                                        possibly can. Please have prepared folllowing corporate documents in
                                        advance: <br/>
                                        <ul>
                                            <li>
                                                Articles of association/Company’s bylaws Signed by Director/authorized
                                                signatory;
                                            </li>
                                            <li>
                                                Extract from local Business Register With visible Company’s name,
                                                address,
                                                status, date of incorporation, director/legal representative;
                                            </li>
                                            <li>
                                                Ownership Structure. Must be dated and signed by Director/authorized
                                                signatory.
                                                Must include natural and legal persons and % of their equity showing the
                                                entire
                                                ownership chain from the Applicant’s entity to its beneficial owners –
                                                natural
                                                persons. During the process, we might require additional documents.
                                                Questions
                                                marked with (*) are mandatory.
                                            </li>
                                        </ul>

                                    </p>
                                </div>

                                <div>
                                    <p>
                                        8. Once the Questionnaire is answered and submitted, below window appears. If
                                        you
                                        notice any errors, details can be edited. If everything is correct, press
                                        “<b>SUBMIT</b>”.
                                    </p>
                                </div>

                                <div>
                                    <p>
                                        9. Your application is being reviewed now. You can review your current
                                        verification
                                        status by clicking on your company name seen in upper right corner of your
                                        internet banking. Then on “<b>Settings</b>” and “<b>Verification</b>”.
                                    </p>
                                </div>

                                <div>
                                    <p className={s.bold}>
                                        Compliance department will contact you via email and request additional
                                        information and documents if needed as well as provide you instructions how to
                                        make transactions if application is successful. Please contact us via
                                        email &nbsp;
                                        <a className={s.link_small}
                                           href="mailto:onboarding@luxpay.lt">onboarding@luxpay.lt</a> if
                                        you have any issues with onboarding process.
                                    </p>
                                </div>

                            </div>
                        </Panel>
                    </Collapse>

                    <Collapse
                        className={s.my_collapse}
                        expandIconPosition={"end"}
                        bordered={false}
                        expandIcon={({isActive}) => isActive ?
                            <img className={s.icon_minus} src={minus} alt=""/> :
                            <img src={plus} className={s.icon_plus} alt=""/>}
                        style={{
                            background: "transparent",
                            alignItems: 'center',
                        }}
                    >
                        <Panel header={"What documents do I need to open a bank account?"} key={3}
                               className={s.panelStyle}>
                            <div className={s.collapse_text}>

                                <div>
                                    <p>
                                        1. You need your Passport or your EU ID card and in case of not being resident
                                        of
                                        the country, a certificate of residency as well.
                                    </p>
                                </div>

                                <div>
                                    <p>
                                        2. If you would like to open a business account, following documents will be
                                        needed:
                                        <ul>
                                            <li>
                                                Articles of association/Company’s bylaws Signed by Director/authorized
                                                signatory.
                                            </li>
                                            <li>
                                                Extract from local Business Register with company’s name, address,
                                                status, date of incorporation, director/legal representative visible on
                                                the document.
                                            </li>
                                            <li>
                                                Ownership Structure. Must be dated and signed by Director/authorized
                                                signatory. Must include natural and legal persons and % of their equity
                                                showing the entire ownership chain from the Applicant’s entity to its
                                                beneficial owners – natural persons.
                                            </li>
                                        </ul>
                                    </p>
                                </div>

                            </div>
                        </Panel>
                    </Collapse>

                    <Collapse
                        className={s.my_collapse}
                        expandIconPosition={"end"}
                        bordered={false}
                        expandIcon={({isActive}) => isActive ?
                            <img className={s.icon_minus} src={minus} alt=""/> :
                            <img src={plus} className={s.icon_plus} alt=""/>}
                        style={{
                            background: "transparent",
                            alignItems: 'center',
                        }}
                    >
                        <Panel header={"How do I know if I have been onboarded?"} key={4}
                               className={s.panelStyle}>
                            <div className={s.collapse_text}>

                                <div>
                                    <p>
                                        You will be contacted via e-mail by LuxPay team.
                                    </p>
                                </div>

                            </div>
                        </Panel>
                    </Collapse>

                    <Collapse
                        className={s.my_collapse}
                        expandIconPosition={"end"}
                        bordered={false}
                        expandIcon={({isActive}) => isActive ?
                            <img className={s.icon_minus} src={minus} alt=""/> :
                            <img src={plus} className={s.icon_plus} alt=""/>}
                        style={{
                            background: "transparent",
                            alignItems: 'center',
                        }}
                    >
                        <Panel header={"What to do if I forgot or blocked my LuxPay login credentials?"} key={5}
                               className={s.panelStyle}>
                            <div className={s.collapse_text}>

                                <div>
                                    <p>
                                        1. Open the link: <a className={s.link_small}
                                                             href="/login">https://luxdev.tehnik.tech/login</a>
                                    </p>
                                </div>

                                <div className={s.flex}>
                                    <div className={s.text}>
                                        2. Press on <a href="#" className={s.link_small}>“Recover” button</a>.
                                    </div>
                                    <div className={s.screenshot}>
                                        <img src={screenshot11} alt=""/>
                                    </div>
                                </div>

                                <div className={s.flex}>
                                    <div className={s.text}>
                                        3. Enter your e-mail and press <a href="#" className={s.link_small}>“Reset”
                                        button</a>.
                                    </div>
                                    <div className={s.screenshot}>
                                        <img src={screenshot12} alt=""/>
                                    </div>
                                </div>

                                <div>
                                    <p>
                                        4. Check your mailbox, you will receive e-mail with following instructions.
                                    </p>
                                </div>

                                <div>
                                    <p>
                                        5. Welcome to your new internet banking.
                                    </p>
                                </div>

                            </div>
                        </Panel>
                    </Collapse>

                    <Collapse
                        className={s.my_collapse}
                        expandIconPosition={"end"}
                        bordered={false}
                        expandIcon={({isActive}) => isActive ?
                            <img className={s.icon_minus} src={minus} alt=""/> :
                            <img src={plus} className={s.icon_plus} alt=""/>}
                        style={{
                            background: "transparent",
                            alignItems: 'center',
                        }}
                    >
                        <Panel header={"Which currencies can I open a bank account at LuxPay?"} key={6}
                               className={s.panelStyle}>
                            <div className={s.collapse_text}>

                                <div>
                                    <p>
                                        At the moment, we provide EUR and GBP  currencies. AED currency will be
                                        available in the near future. <br/>
                                    </p>
                                </div>

                            </div>
                        </Panel>
                    </Collapse>

                    <Collapse
                        className={s.my_collapse}
                        expandIconPosition={"end"}
                        bordered={false}
                        expandIcon={({isActive}) => isActive ?
                            <img className={s.icon_minus} src={minus} alt=""/> :
                            <img src={plus} className={s.icon_plus} alt=""/>}
                        style={{
                            background: "transparent",
                            alignItems: 'center',
                        }}
                    >
                        <Panel header={"What are high risk industries or activities?"} key={7}
                               className={s.panelStyle}>
                            <div className={s.collapse_text}>

                                <div>
                                    <p>
                                        <b>The list of prohibited jurisdictions:</b>
                                    </p>
                                </div>

                                <div>
                                    <ul>
                                        <li>Afghanistan</li>
                                        <li>Cuba</li>
                                        <li>Myanmar</li>
                                        <li>Svalbard</li>
                                        <li>Akrotiri</li>
                                        <li>Democratic Republic of Congo</li>
                                        <li>Navassa Island (UM)</li>
                                        <li>Swaziland</li>
                                        <li>Ashmore and Cartier Islands (under Australia CC)</li>
                                        <li>Dhekelia</li>
                                        <li>Nicaragua</li>
                                        <li>Syria</li>
                                        <li>Baker Island</li>
                                        <li>DPRK</li>
                                        <li>Belarus</li>
                                        <li>Trinidad and Tobago</li>
                                        <li>Barbados</li>
                                        <li>East Timor</li>
                                        <li>Norfolk Island</li>
                                        <li>Senegal</li>
                                        <li>Bouvet Island</li>
                                        <li>Falkland Islands</li>
                                        <li>Pakistan</li>
                                        <li>Uganda</li>
                                        <li>British Indian Ocean Territory</li>
                                        <li>French Southern and Antarctic Lands</li>
                                        <li>Palmyra Atoll (UM)</li>
                                        <li>Vanuatu</li>
                                        <li>Burkina Faso</li>
                                        <li>Haiti</li>
                                        <li>Russia</li>
                                        <li>Wake Island (UM)</li>
                                        <li>Cambodia</li>
                                        <li>Heard Island and McDonald Islands</li>
                                        <li>Panama</li>
                                        <li>Wallis and Futuna</li>
                                        <li>Cayman Islands</li>
                                        <li>Howland Island (UM)</li>
                                        <li>Paracel Islands</li>
                                        <li>Yemen</li>
                                        <li>Gibraltar</li>
                                        <li>Iran</li>
                                        <li>Philippines</li>
                                        <li>Zimbabwe</li>
                                        <li>Christmas Island</li>
                                        <li>Jamaica</li>
                                        <li>Pitcairn Islands</li>
                                        <li>Libya</li>
                                        <li>Clipperton Island (under France CC)</li>
                                        <li>Jan Mayen</li>
                                        <li>Saint Martin</li>
                                        <li>Mali</li>
                                        <li>Cocos (Keeling) Islands</li>
                                        <li>Jarvis Island (UM)</li>
                                        <li>Kenya</li>
                                        <li>Mayotte</li>
                                        <li>Coral Sea Islands (under Australia CC)</li>
                                        <li>Johnston Atoll (UM)</li>
                                        <li>South Georgia and the South Sandwich Islands</li>
                                        <li>Midway Islands</li>
                                        <li>Crimea</li>
                                        <li>Jordan</li>
                                        <li>South Sudan</li>
                                        <li>Morocco</li>
                                        <li>Chad</li>
                                        <li>Kingman Reef (UM)</li>
                                        <li>Spratly Islands</li>
                                    </ul>
                                </div>

                                <div>
                                    <p>
                                        <b>The list of prohibited business fields:</b>
                                    </p>
                                </div>

                                <div>
                                    <ul>
                                        <li>Adult entertainment</li>
                                        <li>Shell banks and companies</li>
                                        <li>Counterfeit items</li>
                                        <li>Signal Jammers/Blockers that interferes with cellular/communication
                                            devices
                                        </li>
                                        <li>Credit repair services</li>
                                        <li>Stolen goods including digital and virtual goods</li>
                                        <li>Drugs or drug proprietors selling illegal substances</li>
                                        <li>Surrogacy services</li>
                                        <li>Drug paraphernalia that involves equipment designed for making or using
                                            drugs
                                        </li>
                                        <li>Tattoo Parlours</li>
                                        <li>Espionage equipment and accessories</li>
                                        <li>Tobacco / Cigar / Electronic Cigarette / Nicotine content products</li>
                                        <li>Human remains and body parts</li>
                                        <li>Trade of weapons, ammunition, military arms, explosive devices and firearm
                                            parts
                                        </li>
                                        <li>Illegal investment schemes</li>
                                        <li>Gambling without a license</li>
                                        <li>Illegal downloads of movies, music, computer and video games</li>
                                        <li>Any other category, products or services that LuxPay decides to prohibit, in
                                            its sole discretion.
                                        </li>
                                        <li>Illegal software – e.g. Malware, Software to break encryption of
                                            phones/computers
                                        </li>
                                        <li>Charities or Not-For-Profit Organizations / NGO’s</li>
                                        <li>Illegal sale of financial information (e.g. bank accounts, bank cards)</li>
                                        <li>Non-account customers</li>
                                        <li>Mail order brides services</li>
                                        <li>Arms, defense, military</li>
                                        <li>Poisonous and hazardous materials</li>
                                        <li>Atomic power</li>
                                        <li>Products/Services promoting abuse, hatred, racism, religious persecution,
                                            terrorism, violence or contain offensive content
                                        </li>
                                        <li>Extractive industries</li>
                                        <li>Pyramid or ponzi schemes</li>
                                        <li>Embassies/Consulates</li>
                                        <li>Sanction list inclusions (global)</li>
                                    </ul>
                                </div>

                            </div>
                        </Panel>
                    </Collapse>

                    <Collapse
                        className={s.my_collapse}
                        expandIconPosition={"end"}
                        bordered={false}
                        expandIcon={({isActive}) => isActive ?
                            <img className={s.icon_minus} src={minus} alt=""/> :
                            <img src={plus} className={s.icon_plus} alt=""/>}
                        style={{
                            background: "transparent",
                            alignItems: 'center',
                        }}
                    >
                        <Panel header={"What services LuxPay provides?"} key={8}
                               className={s.panelStyle}>
                            <div className={s.collapse_text}>

                                <div>
                                    <ul>
                                        <li>Dedicated (individual) IBANs</li>
                                        <li>Access to the e-wallet</li>
                                        <li>Local and cross-border payments in EUR and GBP</li>
                                        <li>1st and 3rd party payments</li>
                                        <li>Payment cards</li>
                                        <li>Easy and understandable platform</li>
                                    </ul>
                                </div>

                                <div>
                                    <p>
                                        <b>Supported currencies:</b>
                                    </p>
                                </div>

                                <div>
                                    <ul>
                                        <li>EUR</li>
                                        <li>GBP</li>
                                    </ul>
                                </div>

                                <div>
                                    <p>
                                        <b>Supported payment rails:</b>
                                    </p>
                                </div>

                                <div>
                                    <ul>
                                        <li>SEPA</li>
                                        <li>Target2</li>
                                        <li>SWIFT</li>
                                        <li>Faster Payment</li>
                                        <li>Fedwire</li>
                                        <li>ACH</li>
                                    </ul>
                                </div>

                            </div>
                        </Panel>
                    </Collapse>

                    <Collapse
                        className={s.my_collapse}
                        expandIconPosition={"end"}
                        bordered={false}
                        expandIcon={({isActive}) => isActive ?
                            <img className={s.icon_minus} src={minus} alt=""/> :
                            <img src={plus} className={s.icon_plus} alt=""/>}
                        style={{
                            background: "transparent",
                            alignItems: 'center',
                        }}
                    >
                        <Panel header={"Why is my payment in a pending state?"} key={11}
                               className={s.panelStyle}>
                            <div className={s.collapse_text}>

                                <div>
                                    <p>
                                        We do our best to process and send your transfer to the recipient as fast as
                                        possible, however, in some cases the payment can be in a pending state a bit
                                        longer. If you see that your payment is in pending state, this simply means that
                                        it is being processed by one of our payment processors. This process can take up
                                        to 2 business days. After you see that payment is in completed state, it means
                                        that it is on the way to the recipient.
                                    </p>
                                </div>

                                <div>
                                    <p>
                                        To avoid any issues and delays, please make sure that the recipient account
                                        details are correct.
                                    </p>
                                </div>

                            </div>
                        </Panel>
                    </Collapse>

                    <Collapse
                        className={s.my_collapse}
                        expandIconPosition={"end"}
                        bordered={false}
                        expandIcon={({isActive}) => isActive ?
                            <img className={s.icon_minus} src={minus} alt=""/> :
                            <img src={plus} className={s.icon_plus} alt=""/>}
                        style={{
                            background: "transparent",
                            alignItems: 'center',
                        }}
                    >
                        <Panel header={"Why has my payment been declined?"} key={12}
                               className={s.panelStyle}>
                            <div className={s.collapse_text}>

                                <div>
                                    <p>
                                        First of all, please make sure that you have sufficient balance on your account
                                        to make a payment. Alternatively, payment also may be declined if you are
                                        attempting to send funds to an unsupported merchant. If you are still having
                                        issues submitting a payment, please contact our support team and we will do our
                                        best to help.
                                    </p>
                                </div>

                            </div>
                        </Panel>
                    </Collapse>

                    <Collapse
                        className={s.my_collapse}
                        expandIconPosition={"end"}
                        bordered={false}
                        expandIcon={({isActive}) => isActive ?
                            <img className={s.icon_minus} src={minus} alt=""/> :
                            <img src={plus} className={s.icon_plus} alt=""/>}
                        style={{
                            background: "transparent",
                            alignItems: 'center',
                        }}
                    >
                        <Panel header={"What recipient account details do I need to know to make a payment?"} key={13}
                               className={s.panelStyle}>
                            <div className={s.collapse_text}>

                                <div>
                                    <p>
                                        <b>
                                            In order to submit a payment, you have to obtain following recipient
                                            details:
                                        </b>
                                    </p>
                                </div>

                                <div>
                                    <ul>
                                        <li>Recipient full name (Same as declared in the recipient's bank)</li>
                                        <li>BIC/SWIFT code – a bank code that has 8 or 11 characters</li>
                                        <li>Recipient’s IBAN – an account number under the recipient’s bank</li>
                                    </ul>
                                </div>
                            </div>
                        </Panel>
                    </Collapse>

                    <Collapse
                        className={s.my_collapse}
                        expandIconPosition={"end"}
                        bordered={false}
                        expandIcon={({isActive}) => isActive ?
                            <img className={s.icon_minus} src={minus} alt=""/> :
                            <img src={plus} className={s.icon_plus} alt=""/>}
                        style={{
                            background: "transparent",
                            alignItems: 'center',
                        }}
                    >
                        <Panel header={"Why recipient has not received a payment I sent?"} key={14}
                               className={s.panelStyle}>
                            <div className={s.collapse_text}>

                                <div>
                                    <p>
                                        <b>
                                            The timeframe for payment to arrive is started to calculate after you can
                                            see
                                            it‘s status as ‘Completed’. Estimated timeframes for the payment to arrive
                                            are:
                                        </b>
                                    </p>
                                </div>

                                <div>
                                    <p>
                                        SWIFT payments – up to 5 working days (usually 3 working days) <br/>
                                        SEPA payments – up to 2 working days (usually 1 wokring day)
                                    </p>
                                </div>

                                <div>
                                    <p>
                                        <b>
                                            Please note! This is an estimation, not a guarantee. In some cases, it might
                                            take slightly longer for the payment to reach its destination:
                                        </b>
                                    </p>
                                </div>

                                <div>
                                    <ul>
                                        <li>Some banks and financial institutions do not process payments during
                                            weekends and bank holidays.
                                        </li>
                                        <li>Some banks and financial institutions may take a longer time to process
                                            payments.
                                        </li>
                                        <li>Some banks and financial institutions are required to make additional checks
                                            on your payments to comply with regulations.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </Panel>
                    </Collapse>

                    <Collapse
                        className={s.my_collapse}
                        expandIconPosition={"end"}
                        bordered={false}
                        expandIcon={({isActive}) => isActive ?
                            <img className={s.icon_minus} src={minus} alt=""/> :
                            <img src={plus} className={s.icon_plus} alt=""/>}
                        style={{
                            background: "transparent",
                            alignItems: 'center',
                        }}
                    >
                        <Panel header={"How quickly recipient will receive my payment?"} key={15}
                               className={s.panelStyle}>
                            <div className={s.collapse_text}>

                                <div>
                                    <p>
                                        EUR payments within SEPA usually arrive on the same day, but might take up to 2
                                        working days. <br/>
                                        International payments (SWIFT) usually reach recipient within 5 working
                                        days. <br/>
                                        Payment timeframes are estimated and can vary from time to time due to the
                                        changes we make to improve our processing systems.
                                    </p>
                                </div>

                            </div>
                        </Panel>
                    </Collapse>

                    <Collapse
                        className={s.my_collapse}
                        expandIconPosition={"end"}
                        bordered={false}
                        expandIcon={({isActive}) => isActive ?
                            <img className={s.icon_minus} src={minus} alt=""/> :
                            <img src={plus} className={s.icon_plus} alt=""/>}
                        style={{
                            background: "transparent",
                            alignItems: 'center',
                        }}
                    >
                        <Panel header={"Will additional fees be applied to my SWIFT payment?"} key={16}
                               className={s.panelStyle}>
                            <div className={s.collapse_text}>

                                <div>
                                    <p style={{textAlign: "justify"}}>
                                        International payments, in other words called SWIFT (Society for Worldwide
                                        Interbank Financial Telecommunication) payments, are those, which sent to or
                                        received from accounts in other countries or payments that are not in your base
                                        currency but are from an account in your country. <br/>
                                        International money transfers are basically inter-bank transfers, however, if
                                        two banks are located in different countries, it makes things slightly more
                                        complex. The principle of correspondent banking applies, which means that two
                                        banks need to have an established relationship in order to facilitate a SWIFT
                                        transfer. SWIFT does not actually send money, it simply sends messages between
                                        the banks. Because of this, other systems that require more human intervention
                                        must be used to transfer the actual funds and this, in turn, makes SWIFT
                                        transfers slow. What’s more, the complex nature of these transfers usually
                                        incurs a fee which nearly always gets passed on to consumers. <br/>
                                        If senders’ and recipient banks do not have direct relationships, one or more
                                        intermediary banks must be found to facilitate the transfer. Unfortunately, each
                                        participant of the payment chain might charge its own fees for processing a
                                        relevant payment. <br/>
                                        LuxPay does charge processing fees for transferring money internationally as per
                                        pricelist. However, a beneficiary or an intermediary bank might additionally
                                        charge their own fees for processing an international transfer. <br/>
                                        A bank that is sending funds to your account could also charge a fee. When your
                                        money is in transit, it might be processed by an intermediary bank who might
                                        also deduct a handling fee. Therefore, the amount received might be less than
                                        the amount sent <br/>
                                        Additionally, you may be charged an FX mark-up if payment is instructed in
                                        different currency that a recipient account is able to receive.
                                    </p>
                                </div>


                                <div>
                                    <p>
                                        <b>
                                            We can provide an estimate of the potential fees charged by the
                                            beneficiary/intermediary
                                            banks for processing a SWIFT payment for the following currencies:
                                        </b>
                                    </p>
                                </div>

                                <div>
                                    <ul>
                                        <li>From 15 EUR</li>
                                        <li>From 20 USD</li>
                                        <li>From 15 GBP</li>
                                        <li>From 50 AED</li>
                                    </ul>
                                </div>

                                <div>
                                    <p>
                                        <b>
                                            Please note! Potential fees mentioned above do not include fees charged by
                                            Luxpay.
                                        </b>
                                    </p>
                                </div>

                            </div>
                        </Panel>
                    </Collapse>

                </div>
            </div>
        </motion.div>

    );
};

export default Faq;
