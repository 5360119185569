export const phonecodes = [
    {"co": "af", "ph": "93", "na": "Afghanistan"},
    {"co": "ax", "ph": "35818", "na": "Aland Islands"},
    {"co": "al", "ph": "355", "na": "Albania"},
    {"co": "dz", "ph": "213", "na": "Algeria"},
    {"co": "ad", "ph": "376", "na": "Andorra"},
    {"co": "ao", "ph": "244", "na": "Angola"},
    {"co": "ai", "ph": "1264", "na": "Anguilla"},
    {"co": "ag", "ph": "1268", "na": "Antigua and Barbuda"},
    {"co": "ar", "ph": "54", "na": "Argentina"},
    {"co": "am", "ph": "374", "na": "Armenia"},
    {"co": "aw", "ph": "297", "na": "Aruba"},
    {"co": "au", "ph": "61", "na": "Australia"},
    {"co": "at", "ph": "43", "na": "Austria"},
    {"co": "az", "ph": "994", "na": "Azerbaijan"},
    {"co": "bs", "ph": "1242", "na": "Bahamas"},
    {"co": "bh", "ph": "973", "na": "Bahrain"},
    {"co": "bd", "ph": "880", "na": "Bangladesh"},
    {"co": "bb", "ph": "1246", "na": "Barbados"},
    {"co": "by", "ph": "375", "na": "Belarus"},
    {"co": "be", "ph": "32", "na": "Belgium"},
    {"co": "bz", "ph": "501", "na": "Belize"},
    {"co": "bj", "ph": "229", "na": "Benin"},
    {"co": "bm", "ph": "1441", "na": "Bermuda"},
    {"co": "bt", "ph": "975", "na": "Bhutan"},
    {"co": "bo", "ph": "591", "na": "Bolivia"},
    {"co": "ba", "ph": "387", "na": "Bosnia"},
    {"co": "bq", "ph": "599", "na": "Bosnia and Herzegovina"},
    {"co": "bw", "ph": "267", "na": "Botswana"},
    {"co": "br", "ph": "55", "na": "Brazil"},
    {"co": "io", "ph": "246", "na": "British Indian Ocean Territory"},
    {"co": "vg", "ph": "1284", "na": "British Virgin Islands"},
    {"co": "bn", "ph": "673", "na": "Brunei"},
    {"co": "bg", "ph": "359", "na": "Bulgaria"},
    {"co": "bf", "ph": "226", "na": "Burkina Faso"},
    {"co": "bi", "ph": "257", "na": "Burundi"},
    {"co": "kh", "ph": "855", "na": "Cambodia"},
    {"co": "cm", "ph": "237", "na": "Cameroon"},
    {"co": "ca", "ph": "1", "na": "Canada"},
    {"co": "cv", "ph": "238", "na": "Cape Verde"},
    {"co": "ky", "ph": "1345", "na": "Cayman islands"},
    {"co": "cf", "ph": "236", "na": "Central African Republic"},
    {"co": "td", "ph": "235", "na": "Chad"},
    {"co": "cl", "ph": "56", "na": "Chile"},
    {"co": "cn", "ph": "86", "na": "China"},
    {"co": "cx", "ph": "61", "na": "Christmas Island"},
    {"co": "cc", "ph": "61", "na": "Cocos Islands"},
    {"co": "co", "ph": "57", "na": "Colombia"},
    {"co": "km", "ph": "269", "na": "Comoros"},
    {"co": "cg", "ph": "242", "na": "Congo"},
    {"co": "ck", "ph": "682", "na": "Cook Islands"},
    {"co": "cr", "ph": "506", "na": "Costa Rica"},
    {"co": "hr", "ph": "385", "na": "Croatia"},
    {"co": "cu", "ph": "53", "na": "Cuba"},
    {"co": "cw", "ph": "599", "na": "Curacao"},
    {"co": "cy", "ph": "357", "na": "Cyprus"},
    {"co": "cz", "ph": "420", "na": "Czech"},
    {"co": "kp", "ph": "850", "na": "Democratic People's Republic of Korea"},
    {"co": "cd", "ph": "243", "na": "Democratic Republic of the Congo"},
    {"co": "dk", "ph": "45", "na": "Denmark"},
    {"co": "dj", "ph": "253", "na": "Djibouti"},
    {"co": "dm", "ph": "1767", "na": "Dominica"},
    {"co": "do", "ph": "1809", "na": "Dominican Republic"},
    {"co": "tl", "ph": "670", "na": "East Timor"},
    {"co": "ec", "ph": "593", "na": "Ecuador"},
    {"co": "eg", "ph": "20", "na": "Egypt"},
    {"co": "gq", "ph": "240", "na": "Equatorial Guinea"},
    {"co": "er", "ph": "291", "na": "Eritrea"},
    {"co": "ee", "ph": "372", "na": "Estonia"},
    {"co": "et", "ph": "251", "na": "Ethiopia"},
    {"co": "fk", "ph": "500", "na": "Falkland Islands"},
    {"co": "fo", "ph": "298", "na": "Faroe Islands"},
    {"co": "fj", "ph": "679", "na": "Fiji"},
    {"co": "fi", "ph": "358", "na": "Finland"},
    {"co": "fr", "ph": "33", "na": "France"},
    {"co": "gf", "ph": "594", "na": "French guiana"},
    {"co": "pf", "ph": "689", "na": "French polynesia"},
    {"co": "ga", "ph": "241", "na": "Gabon"},
    {"co": "gm", "ph": "220", "na": "Gambia"},
    {"co": "ge", "ph": "995", "na": "Georgia"},
    {"co": "de", "ph": "49", "na": "Germany"},
    {"co": "gh", "ph": "233", "na": "Ghana"},
    {"co": "gi", "ph": "350", "na": "Gibraltar"},
    {"co": "gb", "ph": "44", "na": "Great Britain"},
    {"co": "gr", "ph": "30", "na": "Greece"},
    {"co": "gl", "ph": "299", "na": "Greenland"},
    {"co": "gd", "ph": "1473", "na": "Grenada"},
    {"co": "gp", "ph": "590", "na": "Guadeloupe"},
    {"co": "gu", "ph": "1671", "na": "Guam"},
    {"co": "gt", "ph": "502", "na": "Guatemala"},
    {"co": "gn", "ph": "224", "na": "Guinea"},
    {"co": "gw", "ph": "245", "na": "Guinea-Bissau"},
    {"co": "gy", "ph": "592", "na": "Guyana"},
    {"co": "ht", "ph": "509", "na": "Haiti"},
    {"co": "hn", "ph": "504", "na": "Honduras"},
    {"co": "hk", "ph": "852", "na": "Hong Kong"},
    {"co": "hu", "ph": "36", "na": "Hungary"},
    {"co": "is", "ph": "354", "na": "Iceland"},
    {"co": "in", "ph": "91", "na": "India"},
    {"co": "id", "ph": "62", "na": "Indonesia"},
    {"co": "ir", "ph": "98", "na": "Iran"},
    {"co": "iq", "ph": "964", "na": "Iraq"},
    {"co": "ie", "ph": "353", "na": "Ireland"},
    {"co": "il", "ph": "972", "na": "Israel"},
    {"co": "it", "ph": "39", "na": "Italy"},
    {"co": "ci", "ph": "225", "na": "Ivory Coast"},
    {"co": "jm", "ph": "1876", "na": "Jamaica"},
    {"co": "jp", "ph": "81", "na": "Japan"},
    {"co": "jo", "ph": "962", "na": "Jordan"},
    {"co": "kz", "ph": "7", "na": "Kazakhstan"},
    {"co": "ke", "ph": "254", "na": "Kenya"},
    {"co": "ki", "ph": "686", "na": "Kiribati"},
    {"co": "kw", "ph": "965", "na": "Kuwait"},
    {"co": "kg", "ph": "996", "na": "Kyrgyzstan"},
    {"co": "la", "ph": "856", "na": "Laos"},
    {"co": "lv", "ph": "371", "na": "Latvia"},
    {"co": "lb", "ph": "961", "na": "Lebanon"},
    {"co": "ls", "ph": "266", "na": "Lesotho"},
    {"co": "lr", "ph": "231", "na": "Liberia"},
    {"co": "ly", "ph": "218", "na": "Libya"},
    {"co": "li", "ph": "423", "na": "Liechtenstein"},
    {"co": "lt", "ph": "370", "na": "Lithuania"},
    {"co": "lu", "ph": "352", "na": "Luxembourg"},
    {"co": "mo", "ph": "853", "na": "Macao"},
    {"co": "mk", "ph": "389", "na": "Macedonia"},
    {"co": "mg", "ph": "261", "na": "Madagascar"},
    {"co": "mw", "ph": "265", "na": "Malawi"},
    {"co": "my", "ph": "60", "na": "Malaysia"},
    {"co": "mv", "ph": "960", "na": "Maldives"},
    {"co": "ml", "ph": "223", "na": "Mali"},
    {"co": "mt", "ph": "356", "na": "Malta"},
    {"co": "mh", "ph": "692", "na": "Marshall Islands"},
    {"co": "mq", "ph": "596", "na": "Martinique"},
    {"co": "mr", "ph": "222", "na": "Mauritania"},
    {"co": "mu", "ph": "230", "na": "Mauritius"},
    {"co": "yt", "ph": "262", "na": "Mayotte"},
    {"co": "mx", "ph": "52", "na": "Mexico"},
    {"co": "um", "ph": "1", "na": "Minor Outlying Islands (USA)"},
    {"co": "md", "ph": "373", "na": "Moldova"},
    {"co": "mc", "ph": "377", "na": "Monaco"},
    {"co": "mn", "ph": "976", "na": "Mongolia"},
    {"co": "me", "ph": "382", "na": "Montenegro"},
    {"co": "ms", "ph": "1664", "na": "Montserrat"},
    {"co": "ma", "ph": "212", "na": "Morocco"},
    {"co": "mz", "ph": "258", "na": "Mozambique"},
    {"co": "mm", "ph": "95", "na": "Myanmar"},
    {"co": "na", "ph": "264", "na": "Namibia"},
    {"co": "nr", "ph": "674", "na": "Nauru"},
    {"co": "np", "ph": "977", "na": "Nepal"},
    {"co": "nl", "ph": "31", "na": "Netherlands"},
    {"co": "nc", "ph": "687", "na": "New Caledonia"},
    {"co": "nz", "ph": "64", "na": "New Zealand"},
    {"co": "ni", "ph": "505", "na": "Nicaragua"},
    {"co": "ne", "ph": "227", "na": "Niger"},
    {"co": "ng", "ph": "234", "na": "Nigeria"},
    {"co": "nu", "ph": "683", "na": "Niue"},
    {"co": "nf", "ph": "672", "na": "Norfolk Island"},
    {"co": "mp", "ph": "1670", "na": "Northern Mariana Islands"},
    {"co": "no", "ph": "47", "na": "Norway"},
    {"co": "om", "ph": "968", "na": "Oman"},
    {"co": "pk", "ph": "92", "na": "Pakistan"},
    {"co": "pw", "ph": "680", "na": "Palau"},
    {"co": "ps", "ph": "970", "na": "Palestinian territories"},
    {"co": "pa", "ph": "507", "na": "Panama"},
    {"co": "pg", "ph": "675", "na": "Papua New Guinea"},
    {"co": "py", "ph": "595", "na": "Paraguay"},
    {"co": "pe", "ph": "51", "na": "Peru"},
    {"co": "ph", "ph": "63", "na": "Philippines"},
    {"co": "pn", "ph": "870", "na": "Pitcairn"},
    {"co": "pl", "ph": "48", "na": "Poland"},
    {"co": "pt", "ph": "351", "na": "Portugal"},
    {"co": "pr", "ph": "1787", "na": "Puerto Rico"},
    {"co": "qa", "ph": "974", "na": "Qatar"},
    {"co": "ro", "ph": "40", "na": "Romania"},
    {"co": "ru", "ph": "7", "na": "Russian Federation"},
    {"co": "rw", "ph": "250", "na": "Rwanda"},
    {"co": "bl", "ph": "590", "na": "Saint Bartholomew Island"},
    {"co": "sh", "ph": "290", "na": "Saint Helena"},
    {"co": "kn", "ph": "1869", "na": "Saint Kitts and Nevis"},
    {"co": "lc", "ph": "1758", "na": "Saint Lucia"},
    {"co": "mf", "ph": "590", "na": "Saint Martin's Island"},
    {"co": "pm", "ph": "508", "na": "Saint Pierre and Miquelon"},
    {"co": "vc", "ph": "1784", "na": "Saint Vincent and the Grenadines"},
    {"co": "sv", "ph": "503", "na": "Salvador"},
    {"co": "ws", "ph": "685", "na": "Samoa"},
    {"co": "sm", "ph": "378", "na": "San Marino"},
    {"co": "st", "ph": "239", "na": "Sao Tome and Principe"},
    {"co": "sa", "ph": "966", "na": "Saudi Arabia"},
    {"co": "sn", "ph": "221", "na": "Senegal"},
    {"co": "rs", "ph": "381", "na": "Serbia"},
    {"co": "sc", "ph": "248", "na": "Seychelles"},
    {"co": "sl", "ph": "232", "na": "Sierra Leone"},
    {"co": "sg", "ph": "65", "na": "Singapore"},
    {"co": "sx", "ph": "599", "na": "Sint Maarten"},
    {"co": "sk", "ph": "421", "na": "Slovakia"},
    {"co": "si", "ph": "386", "na": "Slovenia"},
    {"co": "sb", "ph": "677", "na": "Solomon islands"},
    {"co": "so", "ph": "252", "na": "Somalia"},
    {"co": "za", "ph": "27", "na": "South Africa"},
    {"co": "ss", "ph": "211", "na": "South Sudan"},
    {"co": "es", "ph": "34", "na": "Spain"},
    {"co": "lk", "ph": "94", "na": "Sri Lanka"},
    {"co": "sd", "ph": "249", "na": "Sudan"},
    {"co": "sr", "ph": "597", "na": "Suriname"},
    {"co": "sz", "ph": "268", "na": "Swaziland"},
    {"co": "se", "ph": "46", "na": "Sweden"},
    {"co": "ch", "ph": "41", "na": "Switzerland"},
    {"co": "sy", "ph": "963", "na": "Syrian Arab Republic"},
    {"co": "tw", "ph": "886", "na": "Taiwan"},
    {"co": "tj", "ph": "992", "na": "Tajikistan"},
    {"co": "tz", "ph": "255", "na": "Tanzania"},
    {"co": "th", "ph": "66", "na": "Thailand"},
    {"co": "kr", "ph": "82", "na": "The Republic of Korea"},
    {"co": "tg", "ph": "228", "na": "Togo"},
    {"co": "tk", "ph": "690", "na": "Tokelau"},
    {"co": "to", "ph": "676", "na": "Tonga"},
    {"co": "tt", "ph": "1868", "na": "Trinidad and Tobago"},
    {"co": "tn", "ph": "216", "na": "Tunisia"},
    {"co": "tr", "ph": "90", "na": "Turkey"},
    {"co": "tm", "ph": "993", "na": "Turkmenistan"},
    {"co": "tc", "ph": "1649", "na": "Turks and Caicos Islands"},
    {"co": "tv", "ph": "688", "na": "Tuvalu"},
    {"co": "ae", "ph": "971", "na": "UAE"},
    {"co": "ug", "ph": "256", "na": "Uganda"},
    {"co": "ua", "ph": "380", "na": "Ukraine"},
    {"co": "us", "ph": "1", "na": "United States"},
    {"co": "uy", "ph": "598", "na": "Uruguay"},
    {"co": "uz", "ph": "998", "na": "Uzbekistan"},
    {"co": "vu", "ph": "678", "na": "Vanuatu"},
    {"co": "va", "ph": "379", "na": "Vatican"},
    {"co": "ve", "ph": "58", "na": "Venezuela"},
    {"co": "vn", "ph": "84", "na": "Vietnam"},
    {"co": "vi", "ph": "1340", "na": "Virgin Islands (US)"},
    {"co": "wf", "ph": "681", "na": "Wallis and Futuna Islands"},
    {"co": "ye", "ph": "967", "na": "Yemen"},
    {"co": "zm", "ph": "260", "na": "Zambia"},
    {"co": "zw", "ph": "263", "na": "Zimbabwe"},
    {"co": "fm", "ph": "691", "na": "micronesia"}
]

export const countries = [
    "Afghanistan",
    "Aland Islands",
    "Albania",
    "Algeria",
    "Andorra",
    "Angola",
    "Anguilla",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Aruba",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bermuda",
    "Bhutan",
    "Bolivia",
    "Bosnia",
    "Bosnia and Herzegovina",
    "Botswana",
    "Brazil",
    "British Indian Ocean Territory",
    "British Virgin Islands",
    "Brunei",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Cape Verde",
    "Cayman islands",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Christmas Island",
    "Cocos Islands",
    "Colombia",
    "Comoros",
    "Congo",
    "Cook Islands",
    "Costa Rica",
    "Croatia",
    "Cuba",
    "Curacao",
    "Cyprus",
    "Czech",
    "Democratic People's Republic of Korea",
    "Democratic Republic of the Congo",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "East Timor",
    "Ecuador",
    "Egypt",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Ethiopia",
    "Falkland Islands",
    "Faroe Islands",
    "Fiji",
    "Finland",
    "France",
    "French guiana",
    "French polynesia",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Gibraltar",
    "Great Britain",
    "Greece",
    "Greenland",
    "Grenada",
    "Guadeloupe",
    "Guam",
    "Guatemala",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Honduras",
    "Hong Kong",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran",
    "Iraq",
    "Ireland",
    "Israel",
    "Italy",
    "Ivory Coast",
    "Jamaica",
    "Japan",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Kuwait",
    "Kyrgyzstan",
    "Laos",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Macao",
    "Macedonia",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Martinique",
    "Mauritania",
    "Mauritius",
    "Mayotte",
    "Mexico",
    "Minor Outlying Islands (USA)",
    "Moldova",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Montserrat",
    "Morocco",
    "Mozambique",
    "Myanmar",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "New Caledonia",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "Niue",
    "Norfolk Island",
    "Northern Mariana Islands",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestinian territories",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Pitcairn",
    "Poland",
    "Portugal",
    "Puerto Rico",
    "Qatar",
    "Romania",
    "Russian Federation",
    "Rwanda",
    "Saint Bartholomew Island",
    "Saint Helena",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Martin's Island",
    "Saint Pierre and Miquelon",
    "Saint Vincent and the Grenadines",
    "Salvador",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Sint Maarten",
    "Slovakia",
    "Slovenia",
    "Solomon islands",
    "Somalia",
    "South Africa",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Swaziland",
    "Sweden",
    "Switzerland",
    "Syrian Arab Republic",
    "Taiwan",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    "The Republic of Korea",
    "Togo",
    "Tokelau",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Turks and Caicos Islands",
    "Tuvalu",
    "UAE",
    "Uganda",
    "Ukraine",
    "United States",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Vatican",
    "Venezuela",
    "Vietnam",
    "Virgin Islands (US)",
    "Wallis and Futuna Islands",
    "Yemen",
    "Zambia",
    "Zimbabwe",
]

export const subjectThemes = [
    'Account opening',
    'Business account',
    'Account settings',
    'IBAN account',
    'Transfers and payments',
    'Currency conversion',
]


export const languages = [
    'EN',
    'FR',
    'RU',
]

export const entityTypes = [
    {
        name: "Sole Proprietorship",
        value: "sole_proprietorship"
    },
    {
        name: "Partnership",
        value: "partnership"
    },
    {
        name: "Privately Owned Company (Limited Company)",
        value: "privately_owned_company"
    },
    {
        name: "Publicly Listed Company (PLC)",
        value: "publicly_owned_company"
    },
    {
        name: "Government Owned Entity Trusts",
        value: "government_owned_entity"
    },
    {
        name: "GO (Majority Owned Subsidiary of State-Owned Company)",
        value: "go"
    },
    {
        name: "Financial Institution",
        value: "financial_institution"
    }
]

export const natureOfBusinessTypes = [
    {
        name: "Personal",
        value: "personal"
    },
    {
        name: "Agriculture and Hunting",
        value: "agriculture_and_hunting"
    },
    {
        name: "Forestry",
        value: "forestry"
    },
    {
        name: "Fishing",
        value: "fishing"
    },
    {
        name: "Agricultural By-Products",
        value: "agricultural_by_products"
    },
    {
        name: "Coal Mining",
        value: "coal_mining"
    },
    {
        name: "Oil Mining",
        value: "oil_mining"
    },
    {
        name: "Iron Ore Mining",
        value: "iron_ore_mining"
    },
    {
        name: "Other Metal and Diamond Mining",
        value: "other_metal_and_diamond_mining"
    },
    {
        name: "Other Mineral Mining",
        value: "other_mineral_mining"
    },
    {
        name: "Manufacture of Food/Drink/Tobacco",
        value: "manufacturing_of_food_drink_tobacco"
    },
    {
        name: "Manufacture of Textiles/Leather/Fur/Furniture",
        value: "manufacturing_of_textiles_leather_fur_furniture"
    },
    {
        name: "Manufacture of Wooden Products/Furniture",
        value: "manufacture_of_wooden_products_furniture"
    },
    {
        name: "Manufacture of Paper/Pulp/Allied Products",
        value: "manufacture_of_paper_pulp_allied_products"
    },
    {
        name: "Manufacture Of Chemicals Medical Petroleum Rubber Plastic Products",
        value: "manufacture_of_chemicals_medical_petroleum_rubber_plastic_products"
    },
    {
        name: "Manufacture Of Pottery China Glass Stone",
        value: "manufacture_of_pottery_china_glass_stone"
    },
    {
        name: "Manufacture Of Iron Steel Non-Ferrous Metals Basic Industries",
        value: "manufacture_of_iron_steel_non_ferrous_metals_basic_industries"
    },
    {
        name: "Manufacture Of Metal Products Electrical And Scientific Engineering",
        value: "manufacture_of_metal_products_electrical_and_scientific_engineering"
    },
    {
        name: "Manufacture Of Jewelry Musical Instruments Toys",
        value: "manufacture_of_jewelry_musical_instruments_toys"
    },
    {
        name: "Electricity, Gas And Water",
        value: "electricity_gas_and_water"
    },
    {
        name: "Construction",
        value: "construction"
    },
    {
        name: "Wholesale Trade",
        value: "wholesale_trade"
    },
    {
        name: "Retail Trade",
        value: "retail_trade"
    },
    {
        name: "Catering Incl. Hotels",
        value: "catering_incl_hotels"
    },
    {
        name: "Transport Storage",
        value: "transport_storage"
    },
    {
        name: "Communications",
        value: "communications"
    },
    {
        name: "Finance And Holding Companies",
        value: "finance_and_holding_companies"
    },
    {
        name: "Insurance",
        value: "insurance"
    },
    {
        name: "Business Services",
        value: "business_services"
    },
    {
        name: "Real Estate Development Investment",
        value: "real_estate_development_investment"
    },
    {
        name: "Central State Governments",
        value: "central_state_governments"
    },
    {
        name: "Community Services Defence Police Prisons Etc",
        value: "community_services_defence_police_prisons_etc"
    },
    {
        name: "Social Services Education Health Care",
        value: "social_services_education_health_care"
    },
    {
        name: "Personal Services - Leisure Services",
        value: "personal_services_leisure_services"
    },
    {
        name: "Personal Services - Domestic Laundry Repairs",
        value: "personal_services_domestic_laundry_repairs"
    },
    {
        name: "Personal Services - Embassies",
        value: "personal_services_embassies_international_organisations"
    }
]

export const typesList = [
    "domestic",
    "international",
]

export const typesListEUR = [
    "SEPA",
    "international",
]