import * as yup from "yup";

const regexDomesticGBP = /^[a-zA-Z0-9\/\-?:().,’+\s#=!"%&*<>;{@\r\n]*$/;
const regexDomesticEUR = /^(?!.*\/\/)(?!.*(^\/|\/$))(?!.*-\s)([A-Za-z0-9\/ -?:().,'+]+)$/;

const regexInternationalEURAndGBP = /^[A-Za-z0-9\s.()\-\/<+&$*%;@="\\]*$/

export const validationSchemaDomesticEUR  = {
    payerAccount: yup.string().required('Required field').matches(regexDomesticEUR, "wrong symbol") ,
    paymentRegions: yup.string().required('Required field').matches(regexDomesticEUR, "wrong symbol"),
    fullName: yup.string().required('Required field').matches(regexDomesticEUR, "wrong symbol").max(70, "max 70 symbols"),
    recipientsIban: yup.string().required('Required field').matches(regexDomesticEUR, "wrong symbol").max(34, "max 34 symbols"),
    recipientsSwiftBic: yup.string().required('Required field').matches(regexDomesticEUR, "wrong symbol").max(11, "max 11 symbols"),
    reference: yup.string().required('Required field').matches(regexDomesticEUR, "wrong symbol").max(140, "max 140 symbols"),
    templateName: yup.string().max(100, 'max 100 symbols'),
};
export const validationSchemaInternationalEUR  = {
    fullName: yup.string().required('Required field').matches(regexInternationalEURAndGBP, "wrong symbol").max(35, "max 35 symbols"),
    recipientsIban: yup.string().required('Required field').matches(regexInternationalEURAndGBP, "wrong symbol").max(34, "max 34 symbols"),
    recipientsSwiftBic: yup.string().required('Required field').matches(regexInternationalEURAndGBP, "wrong symbol").max(11, "max 11 symbols"),
    recipientsAddress: yup.string().required('Required field').matches(regexInternationalEURAndGBP, "wrong symbol").max(105, "max 105 symbols"),
    reference: yup.string().required('Required field').matches(regexInternationalEURAndGBP, "wrong symbol").max(35, "max 35 symbols"),
    payerAccount: yup.string().required('Required field').matches(regexInternationalEURAndGBP, "wrong symbol"),
    paymentRegions: yup.string().required('Required field').matches(regexInternationalEURAndGBP, "wrong symbol"),
    templateName: yup.string().max(100, 'max 100 symbols').nullable().optional(),
};
export const validationSchemaDomesticGBP  = {
    payerAccount: yup.string().required('Required field').matches(regexDomesticGBP, "wrong symbol"),
    fullName: yup.string().required('Required field').matches(regexDomesticGBP, "wrong symbol").max(35, "max 35 symbols"),
    reference: yup.string().required('Required field').matches(regexDomesticGBP, "wrong symbol").max(18, "max 18 symbols"),
    recipientsAccountNumber: yup.string().required('Required field').matches(regexDomesticGBP, "wrong symbol").max(12, "max 12 symbols"),
    sortCode: yup.string().required('Required field').matches(regexDomesticGBP, "wrong symbol").max(6, "max 6 symbols"),
    paymentRegions: yup.string().required('Required field').matches(regexDomesticGBP, "wrong symbol"),
    templateName: yup.string().max(100, 'max 100 symbols').matches(regexDomesticGBP, "wrong symbol"),
};
export const validationSchemaInternationalGBP  = {
    payerAccount: yup.string().required('Required field').matches(regexInternationalEURAndGBP, "wrong symbol"),
    recipientsIban: yup.string().required('Required field').matches(regexInternationalEURAndGBP, "wrong symbol").max(34, "max 34 symbols"),
    recipientsSwiftBic: yup.string().required('Required field').matches(regexInternationalEURAndGBP, "wrong symbol").max(11, "max 11 symbols"),
    fullName: yup.string().required('Required field').matches(regexInternationalEURAndGBP, "wrong symbol").max(35, "max 35 symbols"),
    reference: yup.string().required('Required field').matches(regexInternationalEURAndGBP, "wrong symbol").max(35, "max 35 symbols"),
    recipientsAddress: yup.string().required('Required field').matches(regexInternationalEURAndGBP, "wrong symbol").max(105, "max 105 symbols"),
    paymentRegions: yup.string().required('Required field').matches(regexInternationalEURAndGBP, "wrong symbol"),
    templateName: yup.string().max(100, 'max 100 symbols').matches(regexInternationalEURAndGBP, "wrong symbol"),
};

export const defaultValidation  = yup.object({
    payerAccount: yup.string().required('Required field'),
    paymentRegions: yup.string().required('Required field'),
});
