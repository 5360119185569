import React from 'react';
import s from './MyBtn.module.css'
import classNames from "classnames";
import {useAppSelector} from "../../../hooks/redux";

type PropsType = {
  title: string,
  onClick?: (e?: any) => void;
  type?: string;
  style?: React.CSSProperties;
  icon?: string;
  large?: boolean;
  medium?: boolean;
  small?: boolean;
  buttonType?: 'confirm' | 'error' | 'info';
  localDisable?: boolean;
  isPersonalAccountBtn?: boolean
}

const MyBtn: React.FC<PropsType> = ({
                                        type,
                                        title,
                                        icon,
                                        large,
                                        medium,
                                        small,
                                        buttonType,
                                        localDisable,
                                        isPersonalAccountBtn,
                                        ...props}) => {

    const {disabled} = useAppSelector(store => store.userReducer)

  return (
    <button className={classNames(isPersonalAccountBtn ? s.personal_btn : s.my_btn, {
      [s.large]: large,
      [s.medium]: medium,
      [s.small]: small,
      [s.disabled]: (disabled ? disabled : localDisable),
      [s.confirm]: buttonType === 'confirm',
      [s.error]: buttonType === 'error',
      [s.info]: buttonType === 'info',
    })}
            disabled={disabled ? disabled : localDisable}
            {...props}>
      {
        icon && <img className={s.img} src={icon} alt=""/>
      }
      {title}
    </button>
  );
};

export default MyBtn;
