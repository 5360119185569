import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import s from "./ChangeEmail.module.css";
import MyBtn from "../ui/MyBtn/MyBtn";
import CustomModal from "../ui/CustomModal/CustomModal";
import MyInput from "../ui/MyInput/MyInput";
import {useFormik} from "formik";
import TransparentButton from "../ui/TransparentButton/TransparentButton";
import Icon from "@ant-design/icons";
import {ReactComponent as Back} from "../../assets/img/personalAccount/back.svg";
import info from "../../assets/img/personalAccount/info-circle.svg";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {
    getNewEmailThunk,
    sendCodeToNewEmailThunk,
    sendConfirmationCodeFromOldEmailThunk,
    setTokenState, verifyAndChangeNewEmailThunk, verifyOldEmailThunk
} from "../../store/reducers/ActionCreators";
import {setDisabled} from "../../store/reducers/UserSlice";
import * as yup from "yup";
import styles from "../../styles/styles.module.css"
import iconCheck from "../../assets/img/personalAccount/tick-circle.svg";

type PropsType = {
    setIsChangeEmailProcess: Dispatch<SetStateAction<boolean | null>>
    isChangeEmailProcess: boolean | null
}

const validationSchemaEmail = yup.object({
    newEmail: yup.string().email("invalid format").required('Required field'),
})

const ChangeEmail = (props: PropsType) => {

    const [isVerifyNewEmailModalOpen, setIsVerifyNewEmailModalOpen] = useState<boolean | null>(false)
    const [isShowModalForNewEmail, setIsShowModalForNewEmail] = useState<boolean | null>(false)
    const [isSuccessEmailChanged, setIsSuccessEmailChanged] = useState<boolean | null>(false)
    const [isErrorModalOpen, setIsErrorModalOpen] = useState<boolean | null>(false)
    const [isErrorText, setIsErrorText] = useState('Something went wrong. Please try again later.')
    const [newEmail, setNewEmail] = useState('')
    const dispatch = useAppDispatch()
    const token = useAppSelector(state => state.userReducer.userToken)
    const oldEmail = useAppSelector(state => state.userReducer.user.userData.email)

    useEffect(() => {
        if (props.isChangeEmailProcess) {
            dispatch(verifyOldEmailThunk(token))
        }

    }, [props.isChangeEmailProcess])

    const formikOldEmailVerify = useFormik({
        initialValues: {
            codeFromCurrentEmail: '',
        },
        onSubmit: (values, formikHelpers) => {
            dispatch(sendConfirmationCodeFromOldEmailThunk(values.codeFromCurrentEmail, token))
                .then(() => {
                    setIsShowModalForNewEmail(true)
                })
                .catch((e: any) => {
                    if (e.response.data === "Invalid authorization data") {
                        dispatch(setTokenState(false))
                    } else {
                        setIsErrorText('Invalid email code')
                        setIsErrorModalOpen(true)
                    }
                    formikHelpers.resetForm()
                })
                .finally(() => {
                    dispatch(setDisabled(false))
                })
        }
    })

    const formikNewEmail = useFormik({
        initialValues: {
            newEmail: '',
        },
        enableReinitialize: true,
        validationSchema :validationSchemaEmail,
        onSubmit: (values, formikHelpers) => {
            dispatch(sendCodeToNewEmailThunk(values.newEmail, token))
                .then(() => {
                    setNewEmail(values.newEmail)
                    setIsVerifyNewEmailModalOpen(true)
                })
                .catch((e: any) => {
                    if (e.response.data === 'This email exists, please use other email') {
                        setIsErrorText('This email exists, please use other email')
                    }
                    setIsErrorModalOpen(true)
                })
                .finally(() => {
                    dispatch(setDisabled(false))
                })
        }
    })

    const formikNewEmailVerify= useFormik({
        initialValues: {
            codeFromNewEmail: '',
        },
        onSubmit: (values, formikHelpers) => {
            dispatch(verifyAndChangeNewEmailThunk(newEmail, values.codeFromNewEmail, formikOldEmailVerify.values.codeFromCurrentEmail, token))
                .then(() => {
                    dispatch(getNewEmailThunk(token))
                    props.setIsChangeEmailProcess(false)
                    formikOldEmailVerify.resetForm()
                    formikNewEmail.resetForm()
                    formikHelpers.resetForm()
                    setIsShowModalForNewEmail(false)
                    setIsVerifyNewEmailModalOpen(false)
                    props.setIsChangeEmailProcess(false)
                    setIsSuccessEmailChanged(true)
                })
                .catch((e: any) => {
                    if (e.response.data === "Invalid authorization data") {
                        dispatch(setTokenState(false))
                    } else {
                        setIsErrorText('Invalid email code')
                        setIsErrorModalOpen(true)
                    }
                    formikHelpers.resetForm()
                })
                .finally(() => {
                    dispatch(setDisabled(false))
                })
        }
    })

    return (
        <>
            <CustomModal isModalOpen={props.isChangeEmailProcess} setIsModalOpen={props.setIsChangeEmailProcess} type={"confirm"}>
                <form onSubmit={formikOldEmailVerify.handleSubmit} className={s.from_wrapper}>
                    <div className={s.modal_title}>
                        Email authentication
                    </div>
                    <div className={s.modal_subtitle}>
                        Email with confirmation code was sent to your email address <b>{oldEmail}</b>
                    </div>
                    <div className={s.input_block}>
                        <div className={s.input_label}>
                            confirmation Code
                        </div>
                        <MyInput
                            value={formikOldEmailVerify.values.codeFromCurrentEmail}
                            onChange={formikOldEmailVerify.handleChange}
                            id={"codeFromCurrentEmail"}
                            name={"codeFromCurrentEmail"}
                        />
                    </div>
                    <div className={s.buttonsWrapper}>
                        <TransparentButton
                            buttonType={'confirm'}
                            isPersonalAccountBtn
                            medium
                            style={{width: '100%'}}
                            title={'Cancel'}
                            onClick={() => props.setIsChangeEmailProcess(false)}
                            icon={<Icon component={() => <Back/>} rev={undefined}/>}
                        />
                        <MyBtn
                            buttonType={'confirm'}
                            isPersonalAccountBtn
                            medium
                            style={{width: '100%'}}
                            title={'Confirm'}
                            type={"submit"}
                        />
                    </div>
                </form>
            </CustomModal>

            <CustomModal isModalOpen={isShowModalForNewEmail} setIsModalOpen={setIsShowModalForNewEmail} type={"info"}>
                <form onSubmit={formikNewEmail.handleSubmit} className={s.from_wrapper}>
                    <div className={s.iconBlock}>
                        <img src={info} alt=""/>
                    </div>
                    <div className={s.modal_title}>
                        Change the email address
                    </div>
                    <div className={s.input_block}>
                        <div className={s.input_label}>
                            new email address
                        </div>
                        <MyInput
                            value={formikNewEmail.values.newEmail}
                            onChange={formikNewEmail.handleChange}
                            id={"newEmail"}
                            name={"newEmail"}
                            isError={!!formikNewEmail.errors.newEmail}
                            touched={formikNewEmail.touched.newEmail}
                        />
                        {formikNewEmail.errors.newEmail && <div className={s.error_field}>{formikNewEmail.errors.newEmail}</div>}
                    </div>
                    <div className={s.buttonsWrapper}>
                        <TransparentButton
                            buttonType={'confirm'}
                            medium
                            style={{width: 180}}
                            title={'Cancel'}
                            onClick={() => setIsShowModalForNewEmail(false)}
                            icon={<Icon component={() => <Back/>} rev={undefined}/>}
                            isPersonalAccountBtn
                        />
                        <MyBtn
                            buttonType={'confirm'}
                            medium
                            style={{width: 180}}
                            title={'Next'}
                            type={"submit"}
                            isPersonalAccountBtn
                        />
                    </div>
                </form>
            </CustomModal>

            <CustomModal isModalOpen={isVerifyNewEmailModalOpen} setIsModalOpen={setIsVerifyNewEmailModalOpen} type={"confirm"}>
                <form onSubmit={formikNewEmailVerify.handleSubmit} className={s.from_wrapper}>
                    <div className={s.modal_title}>
                        Email authentication
                    </div>
                    <div className={s.modal_subtitle}>
                        Email with confirmation code was sent to your email address <b>{newEmail}</b>
                    </div>
                    <div className={s.input_block}>
                        <div className={s.input_label}>
                            confirmation Code
                        </div>
                        <MyInput
                            value={formikNewEmailVerify.values.codeFromNewEmail}
                            onChange={formikNewEmailVerify.handleChange}
                            id={"codeFromNewEmail"}
                            name={"codeFromNewEmail"}
                        />
                    </div>
                    <div className={s.buttonsWrapper}>
                        <TransparentButton
                            buttonType={'confirm'}
                            isPersonalAccountBtn
                            medium
                            style={{width: 180}}
                            title={'Cancel'}
                            onClick={() => setIsVerifyNewEmailModalOpen(false)}
                            icon={<Icon component={() => <Back/>} rev={undefined}/>}
                        />
                        <MyBtn
                            buttonType={'confirm'}
                            isPersonalAccountBtn
                            medium
                            style={{width: 180}}
                            title={'Confirm'}
                            type={"submit"}
                        />
                    </div>
                </form>
            </CustomModal>

            <CustomModal isModalOpen={isSuccessEmailChanged} setIsModalOpen={setIsSuccessEmailChanged} type={"confirm"}>
                <div className={styles.modal_content}>
                    <img src={iconCheck} alt="" className={styles.popup_icon}/>
                    <div className={styles.popup_title}>
                        Success!
                    </div>
                    <div className={styles.popup_subtitle}>
                        Your email address has been changed.
                    </div>
                    <div className={styles.popup_button_block}>
                        <MyBtn isPersonalAccountBtn title={"Ok"} onClick={() => setIsSuccessEmailChanged(false)}/>
                    </div>
                </div>
            </CustomModal>

            <CustomModal isModalOpen={isErrorModalOpen} setIsModalOpen={setIsErrorModalOpen} type={"error"}>
                <div className={s.from_wrapper}>
                    <div className={s.modal_title}>
                        Oops!
                    </div>
                    <div className={s.modal_subtitle}>
                        {isErrorText}
                    </div>
                    <div className={s.buttonsWrapper}>
                        <MyBtn
                            buttonType={'error'}
                            isPersonalAccountBtn
                            medium
                            style={{width: 180}}
                            title={'Ok'}
                            onClick={() => setIsErrorModalOpen(false)}
                        />
                    </div>
                </div>
            </CustomModal>
        </>
    );
};

export default ChangeEmail;
