import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {useAppDispatch} from "../../hooks/redux";
import {Formik, useFormik} from "formik";
import * as yup from "yup";
import {motion} from "framer-motion";
import s from "./CreateNewPassword.module.css";
import MyInput from "../ui/MyInput/MyInput";
import MyBtn from "../ui/MyBtn/MyBtn";
import {Link, Navigate, useNavigate, useParams} from "react-router-dom";
import {createNewPasswordThunk, resetPasswordThunk, setNewPasswordThunk} from "../../store/reducers/ActionCreators";
import CustomModal from "../ui/CustomModal/CustomModal";
import modalImg from '../../assets/img/personalAccount/tick-circle.svg'
// import attention from "../../assets/img/attention.svg";

type PropsType = {
    setCurrent?: Dispatch<SetStateAction<number>>
}

const validationSchema = yup.object({
    newPassword: yup.string()
        .min(8, "min 8")
        .matches(/[1-9]+/, "Password must contain at least one number")
        .matches(/[a-z]+/, "Password must contain at least one lowercase")
        .matches(/[A-Z]+/, "Password must contain at least one uppercase")
        .required('Required field'),
    confirmPassword: yup.string()
        .min(8, "min 8")
        .matches(/[1-9]+/, "Password must contain at least one number")
        .matches(/[a-z]+/, "Password must contain at least one lowercase")
        .matches(/[A-Z]+/, "Password must contain at least one uppercase")
        .required('Required field')
        .test('passwords-match', 'Passwords must match', function (value) {
            const { newPassword } = this.parent; 
            return value === newPassword; 
        })
})

const CreateNewPassword = (props: PropsType) => {

    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    
    const { token } = useParams<{ token: string }>();
    const [isModalOpen, setIsModalOpen] = useState<boolean | null>(false);
    const [errorMessage, setErrorMessage] = useState("")
    const [isErrorModalOpen, setIsErrorModalOpen] = useState<boolean | null>(false);
    const [errorText, setErrorText] = useState('');
    const [fullUrl, setFullUrl] = useState<string>('');

    useEffect(() => {
        const queryString = window.location.search;
        const completeUrl = `${token}${queryString}`;
        setFullUrl(completeUrl);
    }, [token]);    

    const closeAndRedirect = () => {
        setIsModalOpen(false)
        if (props.setCurrent) { props.setCurrent(0) }
    }

    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
        >
            <CustomModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} type='confirm'>
                <div className={s.flex}>
                    <img src={modalImg} alt="modalImg" className={s.modal_icon} />
                    <div className={s.modal_title}>
                        Success!
                    </div>
                    <div className={s.modal_subtitle}>
                        Your password has been changed.
                    </div>
                    <div className={s.button_block}>
                        <MyBtn title={"Ok"} onClick={() => closeAndRedirect()} isPersonalAccountBtn large/>
                    </div>
                </div>
            </CustomModal>

            <CustomModal type={"error"} isModalOpen={isErrorModalOpen} setIsModalOpen={setIsErrorModalOpen}>
                <div className={s.flex}>
                    <div className={s.modal_title}>
                        Failed!
                    </div>
                    <div className={s.modal_subtitle}>
                        {errorText}
                    </div>
                    <div className={s.button_block}>
                        <MyBtn title={"Ok"} buttonType={"error"} isPersonalAccountBtn onClick={() => closeAndRedirect()} large />
                    </div>
                </div>
            </CustomModal>

            <Formik
                    initialValues={{
                        newPassword: '',
                        confirmPassword: '',
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values, {resetForm}) => {
                        dispatch(setNewPasswordThunk(fullUrl, values.newPassword, values.confirmPassword))
                            .then((res: any) => {
                                if (res.data == "ok") {
                                    setIsModalOpen(true)
                                    resetForm()
                                }
                            })
                            .catch((e: any) => {
                                if (e.response.data) {
                                    setErrorText(e.response.data)
                                    setIsErrorModalOpen(true)
                                }
                            })
                    }}
                >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleSubmit
                }) => (
                <div className={s.wrapper}>
                    <form onSubmit={handleSubmit} className={s.wrapper_form}>
                        <div className={s.content}>
                            <div className={s.title}>
                                Create a new Password
                            </div>
                            <div className={s.subtitle}>
                                The new password must contain a minimum 8 characters with at least:&nbsp; 
                                <span className={s.subtitle_highlight}>1 lowercase, 1 uppercase, and 1 number.</span>
                            </div>
                            <div className={s.input_block}>
                                <div className={s.input_block}>
                                        <div className={s.input_label}>
                                            * New password
                                        </div>
                                        <MyInput id='newPassword'
                                                 name='newPassword'
                                                 password
                                                 onChange={handleChange}
                                                 value={values.newPassword}
                                                 isError={errors.newPassword}
                                                 touched={touched.newPassword}
                                        />
                                        {errors.newPassword &&
                                            <div className={s.field_error}>{errors.newPassword}</div>}
                                    </div>
                                </div>
                                <div className={s.input_block} style={{marginBottom: '64px'}}>
                                    <div className={s.input_block}>
                                        <div className={s.input_label}>
                                            * Confirm New Password
                                        </div>
                                        <MyInput id='confirmPassword'
                                                 name='confirmPassword'
                                                 password
                                                 onChange={handleChange}
                                                 value={values.confirmPassword}
                                                 isError={errors.confirmPassword}
                                                 touched={touched.confirmPassword}
                                        />
                                        {errors.confirmPassword &&
                                            <div className={s.field_error}>{errors.confirmPassword}</div>}
                                    </div>
                                </div>
                            <div className={s.btn_block}>
                                <MyBtn
                                    title={"Continue"}
                                    type={"submit"}
                                    isPersonalAccountBtn
                                />
                            </div>
                        </div>
                        <div className={s.back}>
                            <div>
                                Back to
                            </div>
                            <Link className={s.link} to={"/login"} 
                              onClick={() => props.setCurrent ? props.setCurrent(0) : null}>
                                Sign In
                            </Link>
                        </div>
                    </form>
                </div>
            )}
        </Formik>

        </motion.div>

    );
};

export default CreateNewPassword;
