import React, { Dispatch, SetStateAction, useState } from 'react';
import { RegistrationThunk, loginVereficationThunk } from "../../../store/reducers/ActionCreators";
import EmailAutenticationLogin
, { EmailVereficationDataType }    from "../../../components/Autentications/EmailAutentication/EmailAutenticationLogin/EmailAutenticationLogin";
import { useAppDispatch } from '../../../store/store';
import {setDisabled} from "../../../store/reducers/UserSlice";

type PropsType = {
    dataForSend: object,
    setCurrent: Dispatch<SetStateAction<number>>
    setDataForSend: () => {}
    setIsErrorModalOpen: Dispatch<SetStateAction<boolean | null>>
}

const RegistrationAutentication: React.FC<PropsType> = ({ ...props }) => {

    const dispatch = useAppDispatch()
    const [error, setError] = useState("")
    const [isEmailVerified, setIsEmailVerified] = useState(false)
    const [isRegistered, setIsRegistered] = useState(false)
    const [confirmCode, setConfirmCode] = useState('')

    const savedData = JSON.parse(localStorage.getItem('registrationData') || '{}');
    const email = savedData.email;

    React.useEffect(() => {
        const fetchEmailCode = () => {
            const savedData = JSON.parse(localStorage.getItem('registrationData') || '{}');
            return savedData.emailCode || '';
        };

        setConfirmCode(fetchEmailCode());

        const intervalId = setInterval(() => {
            const newEmailCode = fetchEmailCode();
            if (newEmailCode !== confirmCode) {
                setConfirmCode(newEmailCode);
            }
        }, 1000); 

        return () => clearInterval(intervalId);
    }, [confirmCode]);

    const data: EmailVereficationDataType = {
        email: email,
        code: confirmCode
    };

    const dataForVerify = {
        'isEmailVerified': isEmailVerified,
        'isRegistered': isRegistered
    }

    localStorage.removeItem('verificationData')
    localStorage.setItem('verificationData', JSON.stringify(dataForVerify));

    const verifyEmail = () => {
        dispatch(loginVereficationThunk(data))
            .then(() => {
                setIsEmailVerified(true)
            })
            .catch((e: any) => {
                setError(e.response.data)
            })
            .finally(() => {
                dispatch(setDisabled(false))
            })
    }

    const createUser = () => {
        dispatch(RegistrationThunk(savedData))
        .then(() => {
            setIsRegistered(true)
        })
        .catch((e: any) => {
            setError(e.response.data)
            dispatch(setDisabled(false))
        })
        .finally(() => {
            dispatch(setDisabled(false))
        })
    }

    return (
        <div>
            <EmailAutenticationLogin
                error={error}
                registration
                email={email}
                dataForSend={props.dataForSend}
                setDataForSend={props.setDataForSend}
                verifyEmail={verifyEmail}
                setCurrent={props.setCurrent}
                createUser={createUser}
                setIsErrorModalOpen={props.setIsErrorModalOpen}
            />
        </div>
    );
};

export default RegistrationAutentication;
