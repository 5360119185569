import React, {Dispatch, SetStateAction} from 'react';
import s from "../../pages/PersonalAccount/Security/Security.module.css";
import style from "./HistoryTable.module.css"
import {ConfigProvider} from "antd";
import TransparentButton from "../ui/TransparentButton/TransparentButton";
import Icon from "@ant-design/icons";
import {ReactComponent as Back} from "../../assets/img/personalAccount/back.svg";
import Table, {ColumnsType} from "antd/es/table";
import {loginHistoryItem} from "../../types/interfaces";

interface DataType {
    key: string;
    date: JSX.Element;
    transferN: string;
    payer: string;
    recipient: string;
    type: string;
    amount: JSX.Element;
    balance: string;
    description?: string,
}

const columns: ColumnsType<DataType> = [
    {
        title: 'Session Start',
        dataIndex: 'sessionStart',
        sorter: true,
    },
    {
        title: 'IP Address',
        dataIndex: 'IPAddress',
    },
    {
        title: 'Session End',
        dataIndex: 'sessionEnd',
    },
    {
        title: 'Duration',
        dataIndex: 'duration',
    },
];

type historyPropsType = {
    setIsHistoryShow: Dispatch<SetStateAction<boolean>>
    loginHistory: loginHistoryItem[]
    isMobile: boolean
}

const HistoryTable = (props: historyPropsType) => {

    const tableData: Array<any> = props.loginHistory.map((item: loginHistoryItem) => {
        return {
            sessionStart: item.sessionStart,
            IPAddress: item.ipAddress,
            sessionEnd: item.sessionEnd,
            duration: item.duration,
        }
    })

    return (
        <>
            {
                props.isMobile
                    ? <div>
                        {
                            props.loginHistory.length > 0
                                ? props.loginHistory.map((item: loginHistoryItem, index) => {
                                    return <div key={index} className={style.history_block}>
                                        {index === 0 && <h4 className={style.page_title}>Login History</h4>}

                                        <div className={style.history_block_row}>
                                            <div className={style.value_block}>
                                                <p className={style.value_label}>Session Start</p>
                                                <p className={style.value}>{item.sessionStart}</p>
                                            </div>
                                            <div className={style.value_block}>
                                                <p className={style.value_label}>Session End</p>
                                                <p className={style.value}>{item.sessionEnd}</p>
                                            </div>
                                        </div>
                                        <div className={style.history_block_row}>
                                            <div className={style.value_block}>
                                                <p className={style.value_label}>IP Address №</p>
                                                <p className={style.value}>{item.ipAddress}</p>
                                            </div>
                                            <div className={style.value_block}>
                                                <p className={style.value_label}>Duration</p>
                                                <p className={style.value}>{item.duration}</p>
                                            </div>
                                        </div>

                                    </div>
                                })
                                : <h4 className={style.page_title}>Login history is empty</h4>
                        }
                    </div>
                    : <div className={s.tableWrapper}>
                        <div className={s.table_title}>
                            Login History
                        </div>
                        <ConfigProvider theme={{
                            components: {
                                Pagination: {
                                    colorPrimary: '#FFFFFF',
                                    colorPrimaryHover: '#FFFFFF',
                                    colorBgContainer: '#4DB887',
                                    colorLink: '#FFFFFF'
                                },
                            },
                        }}>
                            <Table
                                bordered={false}
                                style={{marginTop: 48}}
                                rowClassName={(record, index) => index % 2 !== 0 ? `${s.table_row_dark}` : ''}
                                className={s.statementTable}
                                columns={columns}
                                pagination={{position: ['bottomCenter'], defaultPageSize: 15}}
                                dataSource={tableData}
                            />
                        </ConfigProvider>
                        <div className={s.button_wrapper}>
                            <TransparentButton
                                title={"Back"}
                                isPersonalAccountBtn
                                large
                                icon={<Icon component={() => <Back/>} rev={undefined}/>}
                                onClick={() => props.setIsHistoryShow(false)}
                            />
                        </div>
                    </div>
            }
        </>
    );
};

export default HistoryTable;
