import React, {useState} from 'react';
import KycPersonalStepOne from "./KycPersonalStepOne/KycPersonalStepOne";

const KycPersonal = () => {

    const [current, setCurrent] = useState(0);

    const steps = [
        {
            content: <KycPersonalStepOne setCurrent={setCurrent}
                                         current={current}
            />
        },
    ];

    return (
        <div>{steps[current].content}</div>
    );
};

export default KycPersonal;
